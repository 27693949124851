"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.onIncomingMessage = exports.toggleFullscreen = void 0;
const three_1 = require("three");
const data_1 = require("../shared/data");
const Game_1 = require("./Game");
const ImgLoader_1 = require("./ImgLoader");
const Input_1 = require("./Input");
const ModelLoader_1 = require("./ModelLoader");
const network_1 = require("./network");
const ParticleEffect_1 = require("./ParticleEffect");
const TextureLoader_1 = require("./TextureLoader");
const VisionWorkerController_1 = require("./VisionWorkerController");
const AudioPlayer_1 = require("./AudioPlayer");
const Editor_1 = require("./Editor");
const Map_1 = require("../shared/Map");
let _game = null;
let _editor = null;
let _timeOfLastUpdate = Date.now();
let _timeOfLastFrame = Date.now();
let _lastSnowUpdate = 0;
const _isLocal = location.hostname === "localhost" || location.hostname.includes("s.cc");
const _snowCanvasScale = .25;
const _snowUpdateFreq = 1000 / 20;
const _inventoryDiv = document.getElementById("inventory");
const _uiTopRight = document.getElementById("uiTopRight");
const _hpDiv = document.getElementById("hpDiv");
const _shopDiv = document.getElementById('shopDiv');
const _minimapDiv = document.getElementById('minimapDiv');
const _chatDiv = document.getElementById('chatDiv');
const _importantMessages = document.getElementById('importantMessages');
const _killsDiv = document.getElementById('killsDiv');
const _deathDiv = document.getElementById('deathDiv');
const _loadingDiv = document.getElementById('loadingDiv');
const _mainMenuBGDiv = document.getElementById('mainMenuBGDiv');
const _editorButton = document.getElementById('editorButton');
const _playButton = document.getElementById('playButton');
const _editorDiv = document.getElementById('editorDiv');
const _editorDivBottom = document.getElementById('editorDivBottom');
const _snowCanvas = document.getElementById('snow');
const _snowCtx = _snowCanvas.getContext("2d");
const _rAF = (function () {
    return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (callback) { window.setTimeout(callback, 1000 / 100); };
})();
const _refreshSnow = (now, ctx) => {
    if (now - _lastSnowUpdate < _snowUpdateFreq)
        return;
    _lastSnowUpdate = now;
    const d = ctx.createImageData(_snowCanvas.width, _snowCanvas.height);
    const b = new Uint32Array(d.data.buffer);
    const len = b.length;
    for (let i = 0; i < len; i++) {
        b[i] = ((255 * Math.random()) | 0) << 24;
        // b[i] = (255 << 24) | (255 << 16) | (255 << 8) | ((255 * Math.random()) | 0);
    }
    ctx.putImageData(d, 0, 0);
};
const _resize = () => {
    if (_game !== null)
        _game.resize();
    if (_editor !== null)
        _editor.resize();
    const scale = (window.innerWidth + window.innerHeight) / (1920 + 1080);
    _inventoryDiv.style.transform = `scale(${scale * 1.6})`;
    _uiTopRight.style.transform = `scale(${scale * 1.75})`;
    _hpDiv.style.transform = `scale(${scale * 1.75})`;
    _shopDiv.style.transform = `scale(${scale * 1.3})`;
    _minimapDiv.style.transform = `scale(${scale * 1.6})`;
    _chatDiv.style.transform = `scale(${scale * 1.2})`;
    _importantMessages.style.transform = `scale(${scale * 1.1})`;
    _killsDiv.style.transform = `scale(${scale * 1.25})`;
    _deathDiv.style.transform = `scale(${scale * 1.35})`;
    _snowCanvas.width = window.innerWidth * _snowCanvasScale;
    _snowCanvas.height = window.innerHeight * _snowCanvasScale;
    // place above minimap div (take into account the minimap div scale)
    _shopDiv.style.bottom = `${_minimapDiv.clientHeight * scale * 1.6 + 20 * scale}px`;
};
_resize();
window.onresize = _resize;
const _mainLoop = () => {
    _rAF(_mainLoop);
    const now = Date.now();
    if (_game !== null) {
        const lerpTime = (now - _timeOfLastUpdate) / (1000 / data_1.Global.ticksPerSec);
        _game.onRender(lerpTime);
        (0, VisionWorkerController_1.sendVisionRequest)(_game);
    }
    else if (_editor !== null) {
        _editor.onRender((now - _timeOfLastFrame) / 1000);
        _timeOfLastFrame = now;
    }
    _refreshSnow(now, _snowCtx);
};
_rAF(_mainLoop);
const toggleFullscreen = (element) => {
    if (element.requestFullScreenWithKeys) {
        if (!document.fullScreen)
            element.requestFullScreenWithKeys();
        else
            document.exitFullScreen();
    }
    if (element.requestFullScreen) {
        if (!document.fullScreen)
            element.requestFullscreen();
        else
            document.exitFullScreen();
    }
    else if (element.mozRequestFullScreen) {
        if (!document.mozFullScreen)
            element.mozRequestFullScreen();
        else
            document.mozCancelFullScreen();
    }
    else if (element.webkitRequestFullScreen) {
        if (!document.webkitIsFullScreen)
            element.webkitRequestFullScreen(element.ALLOW_KEYBOARD_INPUT);
        else
            document.webkitCancelFullScreen();
    }
    _resize();
};
exports.toggleFullscreen = toggleFullscreen;
const onIncomingMessage = (msg) => {
    if (msg.init !== undefined) {
        _game = new Game_1.Game(msg.init);
        Input_1.Input.setGame(_game);
        (0, VisionWorkerController_1.sendInitGrid)(_game);
        _mainMenuBGDiv.style.display = "none";
        _loadingDiv.classList.add("hidden");
        _snowCanvas.style.opacity = ".025";
    }
    else if (msg.update !== undefined) {
        if (_game !== null) {
            _game.onUpdate(msg.update);
            _timeOfLastUpdate = Date.now();
        }
    }
    if (msg.goldUpdate !== undefined) {
        if (_game !== null) {
            _game.onGoldUpdate(msg.goldUpdate);
            _timeOfLastUpdate = Date.now();
        }
    }
    if (msg.hp !== undefined) {
        if (_game !== null)
            _game.interface.setHp(msg.hp, msg.killedBy);
    }
    if (msg.usedPoition)
        AudioPlayer_1.AudioPlayer.playSound("potion");
    if (msg.newItem !== undefined) {
        if (_game !== null)
            _game.inventory.addItem(msg.newItem);
    }
    if (msg.itemUpdate !== undefined) {
        if (_game !== null) {
            if (msg.itemUpdate.lvl !== undefined)
                _game.inventory.setLvlFromItemId(msg.itemUpdate.id, msg.itemUpdate.lvl);
            if (msg.itemUpdate.charges !== undefined)
                _game.inventory.setChargesFromItemId(msg.itemUpdate.id, msg.itemUpdate.charges);
            if (msg.itemUpdate.cooldown !== undefined)
                _game.inventory.setCooldownFromItemId(msg.itemUpdate.id, msg.itemUpdate.cooldown);
        }
    }
    if (msg.scanResult !== undefined) {
        if (_game !== null)
            _game.onScanResult(msg.scanResult);
    }
    if (msg.chat !== undefined) {
        if (_game !== null) {
            AudioPlayer_1.AudioPlayer.playSound("zip3");
            _game.interface.addMsg(msg.chat.msg, msg.chat.sender);
        }
    }
    if (msg.respawn) {
        if (_game !== null)
            _game.respawn(msg.respawn);
    }
};
exports.onIncomingMessage = onIncomingMessage;
_editorButton.onclick = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield fetch("map.json");
        const map = yield response.json();
        _editor = new Editor_1.Editor(map);
        Input_1.Input.setEditor(_editor);
    }
    catch (e) {
        const map = (0, Map_1.makeRandomMap)(48, 48);
        _editor = new Editor_1.Editor(map);
        Input_1.Input.setEditor(_editor);
    }
    _mainMenuBGDiv.style.display = "none";
    _editorDiv.style.display = "block";
    _editorDivBottom.style.display = "block";
    _hpDiv.style.display = "none";
    _uiTopRight.style.display = "none";
});
_playButton.onclick = () => {
    _loadingDiv.classList.remove("hidden");
    network_1.Network.send({ join: true });
};
const _load = () => __awaiter(void 0, void 0, void 0, function* () {
    yield ImgLoader_1.ImgLoader.loadImgs("imgs/", "tileSheet.png", "crosshair.png");
    yield ImgLoader_1.ImgLoader.loadImgs("textures/ground/", "dirt.png", "grass.png", "grass2.png", "grass3.jpg", "grass4.jpg", "grassoverlay.png", "flower.png");
    ImgLoader_1.ImgLoader.setImg("flowerYellow", ImgLoader_1.ImgLoader.replaceColors(ImgLoader_1.ImgLoader.getImg("flower.png"), new three_1.Color(1, 1, 0)));
    ImgLoader_1.ImgLoader.setImg("flowerBlue", ImgLoader_1.ImgLoader.replaceColors(ImgLoader_1.ImgLoader.getImg("flower.png"), new three_1.Color(0, .8, 1.4)));
    ImgLoader_1.ImgLoader.setImg("flowerWhite", ImgLoader_1.ImgLoader.replaceColors(ImgLoader_1.ImgLoader.getImg("flower.png"), new three_1.Color(2, 2, 2)));
    ImgLoader_1.ImgLoader.setImg("flowerBlack", ImgLoader_1.ImgLoader.replaceColors(ImgLoader_1.ImgLoader.getImg("flower.png"), new three_1.Color(0, 0, 0)));
    yield ImgLoader_1.ImgLoader.loadImgs("textures/", "circle.png", "shadow.png");
    const waterTexturePaths = [];
    for (let i = 0; i <= 39; i++)
        waterTexturePaths.push("00" + (i < 10 ? "0" : "") + i + ".png");
    yield TextureLoader_1.TextureLoader.loadTextures("textures/water/", ...waterTexturePaths);
    for (let i = 0; i <= 39; i++) {
        const tex = TextureLoader_1.TextureLoader.reduceColorPalette(TextureLoader_1.TextureLoader.getTexture("00" + (i < 10 ? "0" : "") + i + ".png"), 8);
        tex.wrapS = tex.wrapT = three_1.RepeatWrapping;
        tex.repeat.x = tex.repeat.y = 20;
        TextureLoader_1.TextureLoader.setTexture("w00" + (i < 10 ? "0" : "") + i + ".png", tex);
    }
    yield TextureLoader_1.TextureLoader.loadTextures("textures/", "sheep.png", "flame.png", "smoke2.png", "fullCircle.png", "light.png", "fairy.png", "fog2.png", "grassTuft.png", "water.png", "grass.png", "grasses.png", "cross.png");
    yield ModelLoader_1.ModelLoader.loadModelsAsync("models/", "tree1.glb", "tree2.glb", "tree3.glb", "tree4.glb", "trunk.glb", "wall.glb", "stone.glb", "stone2.glb", "path-stone.glb", "mushroom.glb", "mushroom2.glb", "grass.glb", "grass2.glb", "grass3.glb", "flower.glb", "column.glb", "barrel.glb", "shop.glb", "rock.glb", "fountain.glb");
    ParticleEffect_1.ParticleEffect.createImpactEffect("impact", 10);
    ParticleEffect_1.ParticleEffect.createExplosionEffect("explo");
    _loadingDiv.classList.add("hidden");
    network_1.Network.connect();
    if (_isLocal)
        _editorButton.style.display = "block";
});
_load();
