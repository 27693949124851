"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FloatingTextRenderer = void 0;
const three_1 = require("three");
const functions_1 = require("./functions");
const _vec3_0 = new three_1.Vector3();
const _vec3_1 = new three_1.Vector3();
class FloatingTextRenderer {
    constructor() {
        this._floatingTexts = [];
    }
    addFloatingText(tick, pos, text, color) {
        this._floatingTexts.push({
            startTick: tick,
            startPos: pos.clone(),
            direction: new three_1.Vector3(Math.random() - .5, Math.random() + .2, Math.random() - .5).normalize(),
            text,
            color
        });
    }
    onFrame(tick, ctx, camera) {
        for (let i = this._floatingTexts.length - 1; i >= 0; i--) {
            const text = this._floatingTexts[i];
            const age = tick - text.startTick;
            if (age > 40) {
                this._floatingTexts.splice(i, 1);
                continue;
            }
            const pos3d = _vec3_0.copy(text.startPos).addScaledVector(text.direction, (1 / -(1 * age + 1) + 1) * 2);
            const pos2d = (0, functions_1.get2DPosition)(pos3d, camera);
            const size = 700 / camera.position.distanceTo(pos3d);
            ctx.globalAlpha = Math.min(Math.max(0, 2 - age * .1), age * .2);
            (0, functions_1.drawText)(ctx, text.text, text.color, size, "monospace", pos2d.x, pos2d.y, 1000, "center");
            ctx.globalAlpha = 1;
        }
    }
}
exports.FloatingTextRenderer = FloatingTextRenderer;
