"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AudioPlayer = void 0;
const data_1 = require("../shared/data");
const AudioLoader_1 = require("./AudioLoader");
let _playerPos = null;
exports.AudioPlayer = {
    setPlayerPos: (pos) => {
        _playerPos = pos;
    },
    getVolumeModifier: (pos) => {
        if (_playerPos === null)
            return 1;
        const dist = pos.distanceTo(_playerPos);
        if (dist > data_1.Global.sheepVisionRange * 1.5)
            return 0;
        if (dist < data_1.Global.sheepVisionRange)
            return 1;
        return 1 - (dist - data_1.Global.sheepVisionRange) / (data_1.Global.sheepVisionRange * .5);
    },
    playSound: (sound, pos, volumeModifier = 1) => {
        // check distance => volume
        const volume = (pos !== undefined ? exports.AudioPlayer.getVolumeModifier(pos) : 1) * .66 * volumeModifier;
        if (volume <= 0)
            return null;
        const sounds = AudioLoader_1.soundLinks[sound];
        const readySounds = [];
        for (const s of sounds)
            if (s.currentTime >= s.duration || s.currentTime === 0 || !s.currentTime)
                readySounds.push(s);
        // all sounds still in use, return
        if (readySounds.length == 0)
            return null;
        // random one of the ready sounds
        const soundToPlay = readySounds[Math.floor(Math.random() * readySounds.length)];
        // play sound
        soundToPlay.loop = false;
        soundToPlay.src = soundToPlay.src;
        soundToPlay.play();
        soundToPlay.volume = Math.min(volume, 1.0);
        return soundToPlay;
    }
};
