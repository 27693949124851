"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RessourcePool = void 0;
const three_1 = require("three");
const TextureLoader_1 = require("./TextureLoader");
const _planeGeo = new three_1.PlaneGeometry(1, 1);
const _sprites = {};
const _planes = {};
exports.RessourcePool = {
    getSprite: (key, ...args) => {
        if (!(key in _sprites))
            _sprites[key] = [];
        const sprites = _sprites[key];
        for (const s of sprites) {
            if (s.parent === null) {
                s.material.color.set(...args);
                return s;
            }
        }
        const sprite = new three_1.Sprite(new three_1.SpriteMaterial({
            map: TextureLoader_1.TextureLoader.getTexture(key),
            transparent: true,
            depthWrite: false
        }));
        sprites.push(sprite);
        sprite.material.color.set(...args);
        return sprite;
    },
    getPlane: (key) => {
        if (!(key in _planes))
            _planes[key] = [];
        const planes = _planes[key];
        for (const p of planes) {
            if (p.parent === null)
                return p;
        }
        const plane = new three_1.Mesh(_planeGeo, new three_1.MeshToonMaterial({
            map: TextureLoader_1.TextureLoader.getTexture(key),
            transparent: true,
            depthWrite: false
        }));
        planes.push(plane);
        return plane;
    }
};
