"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Game = void 0;
const three_1 = require("three");
const data_1 = require("../shared/data");
const protocoll_1 = require("../shared/protocoll");
const ImgLoader_1 = require("./ImgLoader");
const Interface_1 = require("./Interface");
const network_1 = require("./network");
const Projectile_1 = require("./Projectile");
const rankList_1 = require("./rankList");
const Sheep_1 = require("./Sheep");
const GroundOrderRenderer_1 = require("./GroundOrderRenderer");
const Input_1 = require("./Input");
const SpriteEffectsRenderer_1 = require("./SpriteEffectsRenderer");
const FairyRenderer_1 = require("./FairyRenderer");
const FloatingTextRenderer_1 = require("./FloatingTextRenderer");
const functions_1 = require("./functions");
const tiles_1 = require("../shared/tiles");
const functions_2 = require("../shared/functions");
const AStar_1 = require("../shared/AStar");
const Tile_1 = require("./Tile");
const Shop_1 = require("./Shop");
const Inventory_1 = require("./Inventory");
const TextureLoader_1 = require("./TextureLoader");
const Minimap_1 = require("./Minimap");
const AudioPlayer_1 = require("./AudioPlayer");
const RessourcePool_1 = require("./RessourcePool");
const AmbientPlayer_1 = require("./AmbientPlayer");
const VISION_CANVAS_FIELD_SIZE = 32;
const VISION_SMOOTHING_ITERATIONS = 4;
const _vec2_0 = new three_1.Vector2();
const _vec2_1 = new three_1.Vector2();
const _vec2_2 = new three_1.Vector2();
const _vec2_3 = new three_1.Vector2();
const _vec2_4 = new three_1.Vector2();
const _vec3_0 = new three_1.Vector3();
const _vec3_1 = new three_1.Vector3();
const _vec3_2 = new three_1.Vector3();
const _quat_0 = new three_1.Quaternion();
const _quat_1 = new three_1.Quaternion();
const _quat_2 = new three_1.Quaternion();
const _eul_0 = new three_1.Euler();
const _planeGeo = new three_1.PlaneGeometry(1, 1);
const _canvas = document.getElementById("canvas");
const _2dCanvas = document.getElementById("canvas2");
const _2dCanvasCtx = _2dCanvas.getContext("2d");
const _groundCanvas = document.createElement("canvas");
const _groundCtx = _groundCanvas.getContext("2d");
const _visionCanvas = document.createElement('canvas');
const _visionCtx = _visionCanvas.getContext("2d");
class Game {
    constructor(state) {
        this._sheep = [];
        this._projectiles = [];
        this._playerSheep = null;
        this._playerSheepId = null;
        this._interface = new Interface_1.Interface();
        this._tiles = [];
        this._occludingTiles = [];
        this._scene = new three_1.Scene();
        this._camera = new three_1.PerspectiveCamera(34, window.innerWidth / window.innerHeight, 0.1, 1000);
        this._playerLight = new three_1.PointLight(0xfff2b5, 2.5);
        this._scanCircles = [];
        this._raycaster = new three_1.Raycaster();
        this._groundOrderRenderer = new GroundOrderRenderer_1.GroundOrderRenderer();
        this._floatingTextRenderer = new FloatingTextRenderer_1.FloatingTextRenderer();
        this._spriteEffectsRenderer = new SpriteEffectsRenderer_1.SpriteEffectsRenderer(this._scene);
        this._shopPos = new three_1.Vector3();
        this._grid = [];
        this._pathToRender = [];
        this._map = state.map;
        /*
        for(const s of state.sheep)
            this._addSheep(s);

        for(const p of state.projectiles)
            this._projectiles.push(new Projectile(p, this._scene));
        */
        _groundCanvas.width = state.map.x * data_1.Global.fieldSize;
        _groundCanvas.height = state.map.y * data_1.Global.fieldSize;
        this._ticksCounter = state.tick;
        const img = ImgLoader_1.ImgLoader.getImg(state.map.groundTexture);
        for (let x = 0; x < _groundCanvas.width; x += img.width) {
            for (let y = 0; y < _groundCanvas.height; y += img.height)
                _groundCtx.drawImage(ImgLoader_1.ImgLoader.getImg(state.map.groundTexture), x, y);
        }
        const countFlowers = Math.floor(state.map.x * state.map.y * .1);
        const flowerBlack = ImgLoader_1.ImgLoader.getImg("flowerBlack");
        for (let i = 0; i < countFlowers; i++) {
            const flowerImgName = state.map.flowers[Math.floor(Math.random() * state.map.flowers.length)];
            const flowerImg = ImgLoader_1.ImgLoader.getImg(flowerImgName);
            const scale = (0, functions_2.rand)(.2, .6);
            const x = Math.random() * _groundCanvas.width;
            const y = Math.random() * _groundCanvas.height;
            const angle = Math.random() * 2 * Math.PI;
            _groundCtx.save();
            _groundCtx.translate(x, y + 2);
            _groundCtx.rotate(angle);
            _groundCtx.globalAlpha = .6;
            _groundCtx.drawImage(flowerBlack, -flowerImg.width * scale / 2, -flowerImg.height * scale / 2, flowerImg.width * scale, flowerImg.height * scale);
            _groundCtx.globalAlpha = 1;
            _groundCtx.restore();
            _groundCtx.save();
            _groundCtx.translate(x, y);
            _groundCtx.rotate(angle);
            _groundCtx.drawImage(flowerImg, -flowerImg.width * scale / 2, -flowerImg.height * scale / 2, flowerImg.width * scale, flowerImg.height * scale);
            _groundCtx.restore();
        }
        this._playerSheepId = state.myId;
        // this._playerSheep = this._sheep.find(s => s.id === state.myId) ?? null;
        rankList_1.RankList.init(state.playerList);
        this._camera.position.set(0, 17, 24);
        this._camera.lookAt(0, 0, 2);
        this._renderer = new three_1.WebGLRenderer({
            antialias: true,
            canvas: _canvas,
            precision: "lowp",
            powerPreference: "high-performance"
        });
        const x = state.map.x + data_1.Global.mapSideDeadZone * 2;
        const y = state.map.y + data_1.Global.mapTopDeadZone + data_1.Global.mapBottomDeadZone;
        this._groundPlaneGeo = new three_1.PlaneGeometry(x, y, x, y);
        const groundTexture = new three_1.CanvasTexture(_groundCanvas);
        this._ground = new three_1.Mesh(this._groundPlaneGeo, new three_1.MeshToonMaterial({ map: groundTexture }));
        this._ground.receiveShadow = true;
        this._visionTexture = new three_1.CanvasTexture(_visionCanvas);
        this._visionGround = new three_1.Mesh(this._groundPlaneGeo, new three_1.MeshBasicMaterial({
            map: this._visionTexture,
            transparent: true,
            depthWrite: false
        }));
        groundTexture.wrapS = groundTexture.wrapT = this._visionTexture.wrapS = this._visionTexture.wrapT = three_1.RepeatWrapping;
        groundTexture.repeat.x = this._visionTexture.repeat.x = x / state.map.x;
        groundTexture.repeat.y = this._visionTexture.repeat.y = y / state.map.y;
        groundTexture.offset.x = this._visionTexture.offset.x = (-data_1.Global.mapSideDeadZone) / state.map.x;
        groundTexture.offset.y = this._visionTexture.offset.y = (-data_1.Global.mapBottomDeadZone) / state.map.y;
        this._waterMaterial = new three_1.MeshToonMaterial({
            map: TextureLoader_1.TextureLoader.getTexture("w0000.png"),
            transparent: true,
            opacity: .75,
            depthWrite: false
        });
        this._water = new three_1.Mesh(new three_1.PlaneGeometry(x, y, 1, 1), this._waterMaterial);
        this.applyHeightMap();
        this._ground.rotation.x = this._visionGround.rotation.x = this._water.rotation.x = -Math.PI * .5;
        this._visionGround.position.set(state.map.x * .5, .01, -data_1.Global.mapTopDeadZone + (data_1.Global.mapTopDeadZone + state.map.y + data_1.Global.mapBottomDeadZone) * .5);
        this._ground.position.copy(this._visionGround.position).setY(0);
        this._visionGround.renderOrder = -1;
        this._scene.add(this._visionGround, this._ground, this._playerLight, this._water);
        this._water.position.set(0, 2.5, this._map.y);
        this._scene.add(new three_1.AmbientLight(0xfeffbf, .6));
        // this._scene.add(new HemisphereLight(0xfffffe, 0x111111, .5));
        const sun = new three_1.DirectionalLight(0xfeffbf, .85);
        sun.position.set(3, 10, 1);
        sun.castShadow = true;
        sun.shadow.mapSize.width = 2048;
        sun.shadow.mapSize.height = 2048;
        this._scene.add(sun);
        this._scene.fog = new three_1.FogExp2(0x2f426a, .035);
        this._renderer.shadowMap.enabled = true;
        this._renderer.shadowMap.type = three_1.PCFSoftShadowMap;
        _visionCanvas.width = state.map.x * VISION_CANVAS_FIELD_SIZE;
        _visionCanvas.height = state.map.y * VISION_CANVAS_FIELD_SIZE;
        this._fairyRenderer = new FairyRenderer_1.FairyRenderer(this._scene, FairyRenderer_1.FairyType.Fairy);
        this._fogRenderer = new FairyRenderer_1.FairyRenderer(this._scene, FairyRenderer_1.FairyType.Fog);
        this._initGrid();
        this._createTiles();
        this.resize();
        this._aStar = new AStar_1.AStar(this._grid);
        this._minimap = new Minimap_1.Minimap(state.map);
        this._inventory = new Inventory_1.Inventory(this._interface);
        this._shopUI = new Shop_1.ShopUI(this._shopPos, this._interface, this._inventory);
        // AudioPlayer.playSound("spawn");
    }
    respawn(playerSheepId) {
        this._playerSheepId = playerSheepId;
        const playerSheep = this._sheep.find(s => s.id === playerSheepId);
        if (playerSheep !== undefined)
            this._playerSheep = playerSheep;
        this._interface.onRespawn();
    }
    get inventory() {
        return this._inventory;
    }
    get ground() {
        return this._ground;
    }
    get groundCtx() {
        return _groundCtx;
    }
    get visionCanvas() {
        return _visionCanvas;
    }
    get visionCtx() {
        return _visionCtx;
    }
    get visionTexture() {
        return this._visionTexture;
    }
    get camera() {
        return this._camera;
    }
    addObject(obj) {
        this._scene.add(obj);
    }
    _createTiles() {
        _groundCtx.globalAlpha = .5;
        for (const mapTile of this._map.tiles) {
            const type = tiles_1.tileTypes.find(t => t.id === mapTile.type);
            if (type !== undefined) {
                const t = this.addTile(type, mapTile.x, mapTile.y, mapTile.z, mapTile.scale, _eul_0.set(mapTile.rot.x, mapTile.rot.y, mapTile.rot.z));
                if (type.isShop)
                    this._shopPos.copy(t.getModelPosition());
            }
        }
        _groundCtx.globalAlpha = 1;
    }
    addTile(type, x, y, z, scale = 1.0, rotation) {
        const t = new Tile_1.Tile(type, x, y, z, this._map.heightMap, _groundCtx, this._scene, scale, rotation);
        this._tiles.push(t);
        if (type.isOccluding)
            this._occludingTiles.push(t);
        return t;
    }
    removeTile(tile) {
        this._tiles.splice(this._tiles.indexOf(tile), 1);
        this._occludingTiles.splice(this._occludingTiles.indexOf(tile), 1);
        tile.onDestroy(this._scene);
    }
    get tiles() {
        return this._tiles;
    }
    refreshTileYPositions() {
        for (const t of this._tiles)
            t.setYPosition(this._map.heightMap);
    }
    _initGrid() {
        this._grid.length = 0;
        for (let x = 0; x < this._map.x; x++) {
            this._grid[x] = [];
            for (let y = 0; y < this._map.y; y++) {
                if (x in this._map.collision && y in this._map.collision[x])
                    this._grid[x][y] = this._map.collision[x][y];
                else
                    this._grid[x][y] = x <= 0 || y <= 0 ? 4 : 0;
            }
        }
    }
    get ticksCounter() {
        return this._ticksCounter;
    }
    get grid() {
        return this._grid;
    }
    updateVisionCanvas(paths) {
        _visionCanvas.width = _visionCanvas.width;
        _visionCtx.fillStyle = "rgba(0, 0, 0, .5)";
        _visionCtx.fillRect(0, 0, _visionCanvas.width, _visionCanvas.height);
        _visionCtx.globalCompositeOperation = "destination-out";
        _visionCtx.beginPath();
        for (const visionPath of paths) {
            const fields = visionPath.fields;
            _visionCtx.moveTo((fields[0].x + this._map.x * .0) * VISION_CANVAS_FIELD_SIZE, (fields[0].y + this._map.y * .0) * VISION_CANVAS_FIELD_SIZE);
            for (let i = 1; i < fields.length; i++) {
                if (fields[i].m && fields[i - 1].m) {
                    _visionCtx.arc((visionPath.origin.x + this._map.x * .0) * VISION_CANVAS_FIELD_SIZE, (visionPath.origin.y + this._map.y * .0) * VISION_CANVAS_FIELD_SIZE, visionPath.v * VISION_CANVAS_FIELD_SIZE, fields[i - 1].a, fields[i].a);
                }
                else {
                    _visionCtx.lineTo((fields[i].x + this._map.x * .0) * VISION_CANVAS_FIELD_SIZE, (fields[i].y + this._map.y * .0) * VISION_CANVAS_FIELD_SIZE);
                }
            }
            _visionCtx.closePath();
        }
        _visionCtx.fillStyle = "rgba(255, 255, 255, 1)";
        _visionCtx.fill();
        for (let k = 0; k < VISION_SMOOTHING_ITERATIONS; k++) {
            _visionCtx.strokeStyle = "rgba(255, 255, 255, " + (1 / (VISION_SMOOTHING_ITERATIONS + 1 - k)) + ")";
            _visionCtx.lineWidth = 16 * (1 - k / VISION_SMOOTHING_ITERATIONS);
            _visionCtx.stroke();
        }
        _visionCtx.globalCompositeOperation = "source-over";
        this._visionTexture.needsUpdate = true;
    }
    getPosFromMouseCoords(screenPos) {
        this._raycaster.setFromCamera(screenPos, this._camera);
        const intersects = this._raycaster.intersectObject(this._ground);
        if (intersects.length > 0)
            return intersects[0].point;
        return null;
    }
    getUnitAtPosition(screenPos) {
        this._raycaster.setFromCamera(screenPos, this._camera);
        for (const u of this._sheep) {
            const collider = u.updateAndGetCollisionSphere();
            if (collider !== null && this._raycaster.ray.intersectsSphere(collider))
                return u;
        }
        return null;
    }
    getTileAtPosition(screenPos) {
        this._raycaster.setFromCamera(screenPos, this._camera);
        for (const t of this._tiles) {
            if (this._raycaster.ray.intersectsBox(t.collider))
                return t;
        }
        return null;
    }
    _sheepIsOccludedBy(sheep, collider) {
        const direction = _vec3_0.subVectors(sheep.getModelPos(), this._camera.position).normalize();
        this._raycaster.set(this._camera.position, direction);
        return this._raycaster.ray.intersectsBox(collider);
    }
    get interface() {
        return this._interface;
    }
    getPlayerSheep() {
        return this._playerSheep;
    }
    getVisionSheep() {
        const s = [];
        if (this._playerSheep !== null)
            s.push(this._playerSheep);
        return s;
    }
    // return final position
    orderMove(pos, shift) {
        if (this._playerSheep === null)
            return null;
        const path = this._aStar.getPath(this._playerSheep.pos, pos).map(p => ({ x: p.x, y: p.y })).reverse();
        this._pathToRender = path;
        network_1.Network.send({
            input: {
                cmd: protocoll_1.Command.Move,
                pos: path
            }
        });
        return path.length > 0 ? path[path.length - 1] : null;
    }
    orderAttack(target, shift) {
        network_1.Network.send({
            input: {
                cmd: protocoll_1.Command.Attack,
                id: target.id
            }
        });
    }
    createGroundOrder(pos) {
        this._groundOrderRenderer.createGroundOrder(this._ticksCounter, pos);
    }
    _addSheep(state) {
        var _a;
        const sheep = new Sheep_1.Sheep(state, this._scene, this._grid, (_a = this._playerSheep) === null || _a === void 0 ? void 0 : _a.pos);
        this._sheep.push(sheep);
        if (state.id === this._playerSheepId)
            this._playerSheep = sheep;
    }
    onGoldUpdate(goldUpdate) {
        this._interface.updateGold(goldUpdate.amountAbs);
        const pos = _vec3_0.set(goldUpdate.pos.x, 0, goldUpdate.pos.y);
        pos.y = (0, functions_1.getGroundHeightAtPosition)(pos.x, pos.z, this._map.heightMap);
        this._floatingTextRenderer.addFloatingText(this._ticksCounter, pos, (goldUpdate.amountRel > 0 ? "+" : "") + goldUpdate.amountRel, "gold");
    }
    onUpdate(state) {
        var _a;
        this._ticksCounter = state.tick;
        if (state.hits !== undefined) {
            for (const hit of state.hits) {
                const proj = this._projectiles.find(p => p.id === hit.projId);
                if (proj === undefined)
                    continue;
                proj.onHit(this._scene, this._spriteEffectsRenderer, this._ticksCounter, this.playerCanSee(proj.x, proj.z));
                this._projectiles.splice(this._projectiles.indexOf(proj), 1);
                const sheep = this._sheep.find(s => s.id === hit.sheepId);
                if (sheep !== undefined) {
                    sheep.onHit(this._ticksCounter);
                    if (hit.dies)
                        sheep.onDeath(this._spriteEffectsRenderer, this._ticksCounter, this.playerCanSee(sheep.pos.x, sheep.pos.y));
                }
            }
        }
        for (let i = this._sheep.length - 1; i >= 0; i--) {
            const s = this._sheep[i];
            if (state.sheep.find(state => state.id === s.id) === undefined) {
                s.onRemove(this._scene);
                this._sheep.splice(i, 1);
            }
        }
        for (const sheepState of state.sheep) {
            const s = this._sheep.find(s => s.id === sheepState.id);
            if (s !== undefined)
                s.onUpdate(this._ticksCounter, this._grid, sheepState, this._map.heightMap, this._spriteEffectsRenderer, (_a = this._playerSheep) === null || _a === void 0 ? void 0 : _a.pos);
            else
                this._addSheep(sheepState);
        }
        for (const projectileState of state.projectiles) {
            const projectile = this._projectiles.find(projectile => projectile.id === projectileState.id);
            if (projectile !== undefined)
                projectile.onUpdate(projectileState, this._spriteEffectsRenderer, this.ticksCounter, this._camera, this.playerCanSee(projectileState.pos.x, projectileState.pos.z));
            else
                this._projectiles.push(new Projectile_1.Projectile(projectileState, this._scene, this.playerCanSee(projectileState.pos.x, projectileState.pos.z)));
        }
        this._interface.onTick(this._ticksCounter);
        this._inventory.onTick();
        this._spriteEffectsRenderer.onTick(this._ticksCounter);
        this._minimap.onTick();
        if (state.remPlayers !== undefined) {
            for (const id of state.remPlayers)
                rankList_1.RankList.remove(id);
        }
        if (state.newPlayers !== undefined) {
            for (const player of state.newPlayers)
                rankList_1.RankList.add(player.id, player.name, player.score);
        }
        if (state.kills !== undefined) {
            for (const kill of state.kills)
                rankList_1.RankList.addKillMsg(kill.killerId, kill.victimId, kill.score);
        }
        if (state.heals !== undefined) {
            for (const heal of state.heals) {
                if (this.playerCanSee(heal.x, heal.y)) {
                    this._spriteEffectsRenderer.createSprite({
                        pos: _vec3_0.set(heal.x + (0, functions_2.rand)(-.3, .3), (0, functions_1.getGroundHeightAtPosition)(heal.x, heal.y, this._map.heightMap) + .6 + (0, functions_2.rand)(-.2, .2), heal.y + (0, functions_2.rand)(-.3, .3)),
                        sprite: RessourcePool_1.RessourcePool.getSprite("cross.png", 0x85ff5b),
                        velocity: _vec3_1.set((0, functions_2.rand)(-.1, .1), (0, functions_2.rand)(.5, .7), (0, functions_2.rand)(-.1, .1)),
                        moveFunc: age => age * .9,
                        alphaFunc: age => Math.min((1 - age / .6) * 1.5, 1),
                        timeToLive: (0, functions_2.rand)(.9, 1.4),
                        scaleFunc: age => .15 + ((age * 5) + Math.pow((age * 5), 2)) / (1 + Math.pow((age * 5), 3)) * .2
                    }, this._ticksCounter);
                }
            }
        }
        if (this._playerSheep !== null) {
            const pos = _vec3_0.copy(this._camera.position).add(this._playerSheep.getModelPos()).multiplyScalar(.5);
            this._fairyRenderer.onTick(pos);
            this._fogRenderer.onTick(pos);
            this._shopUI.onUpdate(this._playerSheep.pos);
        }
        for (const tile of this._occludingTiles) {
            const isOccluding = this._playerSheep !== null && tile.x > this._playerSheep.pos.x - 3 && tile.x < this._playerSheep.pos.x + 3
                && tile.z > this._playerSheep.pos.y && tile.z < this._playerSheep.pos.y + 20 && this._sheepIsOccludedBy(this._playerSheep, tile.collider);
            tile.onUpdate(isOccluding ? .2 : 1);
        }
    }
    playerCanSee(x, y) {
        if (this._playerSheep === null)
            return true;
        const target = _vec2_2.set(x, y);
        if (target.distanceToSquared(this._playerSheep.pos) > data_1.Global.sheepVisionRangeSquared)
            return false;
        return (0, functions_2.raycast)(_vec2_3.set(this._playerSheep.pos.x, this._playerSheep.pos.y), target, this._grid, _vec2_4).equals(target);
    }
    resize() {
        _canvas.width = _2dCanvas.width = window.innerWidth;
        _canvas.height = _2dCanvas.height = window.innerHeight;
        this._camera.aspect = window.innerWidth / window.innerHeight;
        this._camera.updateProjectionMatrix();
        this._renderer.setSize(window.innerWidth, window.innerHeight);
    }
    onScanResult(result) {
        this._minimap.onScan(result.positions);
        if (this._playerSheep !== null && result.duration !== undefined) {
            AudioPlayer_1.AudioPlayer.playSound("scan");
            this._interface.addImportantMsg("Enemy positions visible", result.duration, "rgba(100, 255, 100)");
            this._scanCircles.push({ x: this._playerSheep.pos.x, y: this._playerSheep.pos.y, tickOfSpawn: this._ticksCounter });
        }
    }
    onRender(lerpTime) {
        var _a;
        _2dCanvasCtx.clearRect(0, 0, _2dCanvas.width, _2dCanvas.height);
        const exactTicks = this._ticksCounter + lerpTime;
        const hoverUnit = this.getUnitAtPosition(Input_1.Input.getScreenPos(_vec2_1));
        this._groundOrderRenderer.onFrame(this._ticksCounter, _visionCtx);
        this._floatingTextRenderer.onFrame(this._ticksCounter + lerpTime, _2dCanvasCtx, this._camera);
        this._interface.onFrame();
        this._spriteEffectsRenderer.onFrame(exactTicks, lerpTime);
        for (const o of this._sheep)
            o.onFrame(lerpTime, this._map.heightMap);
        for (const p of this._projectiles)
            p.onFrame(lerpTime, exactTicks, this._map.heightMap);
        for (let i = this._scanCircles.length - 1; i >= 0; i--) {
            const c = this._scanCircles[i];
            if (this._ticksCounter - c.tickOfSpawn > 40)
                this._scanCircles.splice(i, 1);
            else {
                _visionCtx.beginPath();
                _visionCtx.lineWidth = 3;
                _visionCtx.arc(c.x * VISION_CANVAS_FIELD_SIZE, c.y * VISION_CANVAS_FIELD_SIZE, (exactTicks - c.tickOfSpawn) * VISION_CANVAS_FIELD_SIZE, 0, Math.PI * 2);
                _visionCtx.strokeStyle = `rgba(100, 255, 100, ${Math.min(4 - (exactTicks - c.tickOfSpawn) / 10, 1)})`;
                _visionCtx.stroke();
            }
        }
        this._minimap.onFrame(_visionCanvas, (_a = this._playerSheep) === null || _a === void 0 ? void 0 : _a.drawPos);
        if (this._playerSheep !== null) {
            const playerPos = this._playerSheep.getModelPos();
            this._camera.position.copy(playerPos);
            this._camera.position.y += 12;
            this._camera.position.z += 18;
            this._camera.lookAt(playerPos.x, playerPos.y - 1, playerPos.z);
            this._playerLight.position.copy(playerPos);
            this._playerLight.position.y += 2.5;
            if (this._pathToRender.length > 0 && data_1.DEBUG) {
                _visionCtx.beginPath();
                _visionCtx.moveTo(this._playerSheep.drawPos.x * VISION_CANVAS_FIELD_SIZE, this._playerSheep.drawPos.y * VISION_CANVAS_FIELD_SIZE);
                for (const p of this._pathToRender)
                    _visionCtx.lineTo(p.x * VISION_CANVAS_FIELD_SIZE, p.y * VISION_CANVAS_FIELD_SIZE);
                _visionCtx.strokeStyle = "rgba(255, 255, 255, .5)";
                _visionCtx.lineWidth = 2;
                _visionCtx.stroke();
            }
        }
        this._fairyRenderer.onFrame(lerpTime);
        this._fogRenderer.onFrame(lerpTime);
        this._shopUI.onFrame(this._camera);
        for (const s of this._sheep) {
            const opac = s.getOpacity();
            if (opac <= 0)
                continue;
            const pos = s.drawPos;
            _visionCtx.globalAlpha = .4 * opac;
            _visionCtx.drawImage(ImgLoader_1.ImgLoader.getImg("shadow.png"), pos.x * VISION_CANVAS_FIELD_SIZE - VISION_CANVAS_FIELD_SIZE * .4, pos.y * VISION_CANVAS_FIELD_SIZE - VISION_CANVAS_FIELD_SIZE * .4, VISION_CANVAS_FIELD_SIZE * .8, VISION_CANVAS_FIELD_SIZE * .8);
            _visionCtx.globalAlpha = 1;
            if (s !== this._playerSheep && s.hoverCircleIsActive(this._ticksCounter, s === hoverUnit)) {
                for (let i = 0; i < 2; i++) {
                    _visionCtx.drawImage(ImgLoader_1.ImgLoader.getImg("circle.png"), pos.x * VISION_CANVAS_FIELD_SIZE - VISION_CANVAS_FIELD_SIZE * .5, pos.y * VISION_CANVAS_FIELD_SIZE - VISION_CANVAS_FIELD_SIZE * .5, VISION_CANVAS_FIELD_SIZE, VISION_CANVAS_FIELD_SIZE);
                }
            }
        }
        const activeSlot = this.inventory.getActiveSlot();
        if (activeSlot !== null && activeSlot.type.tpDistanceAtLvl !== undefined && this._playerSheep !== null) {
            const target = this.getPosFromMouseCoords(Input_1.Input.getScreenPos(_vec2_0));
            const range = activeSlot.type.tpDistanceAtLvl(activeSlot.lvl);
            if (target !== null) {
                const target2d = _vec2_1.set(target.x, target.z);
                if (this._playerSheep.pos.distanceTo(target2d) > range)
                    target2d.sub(this._playerSheep.pos).setLength(range).add(this._playerSheep.pos);
                _visionCtx.save();
                _visionCtx.translate(target2d.x * VISION_CANVAS_FIELD_SIZE, target2d.y * VISION_CANVAS_FIELD_SIZE);
                _visionCtx.rotate(exactTicks * .06);
                const img = ImgLoader_1.ImgLoader.getImg("crosshair.png");
                const scale = 1.5;
                _visionCtx.drawImage(img, -VISION_CANVAS_FIELD_SIZE * scale / 2, -VISION_CANVAS_FIELD_SIZE * scale / 2, VISION_CANVAS_FIELD_SIZE * scale, VISION_CANVAS_FIELD_SIZE * scale);
                _visionCtx.restore();
            }
            _visionCtx.beginPath();
            _visionCtx.lineWidth = 4;
            const r = VISION_CANVAS_FIELD_SIZE * (range + data_1.Global.sheepRadius + .1);
            const angle = exactTicks * .025;
            _visionCtx.arc(this._playerSheep.drawPos.x * VISION_CANVAS_FIELD_SIZE, this._playerSheep.drawPos.y * VISION_CANVAS_FIELD_SIZE, r, angle, angle + Math.PI * 2);
            _visionCtx.setLineDash([8, 8]);
            _visionCtx.strokeStyle = "white";
            _visionCtx.stroke();
            _visionCtx.setLineDash([]);
        }
        const waterFrame = Math.floor(Date.now() * .01 % 40);
        this._waterMaterial.map = TextureLoader_1.TextureLoader.getTexture("w00" + (waterFrame < 10 ? "0" : "") + waterFrame + ".png");
        this._waterMaterial.needsUpdate = true;
        this._waterMaterial.map.offset.x = this._waterMaterial.map.offset.y = (Date.now() * .00004) % 1;
        this._renderer.render(this._scene, this._camera);
        AmbientPlayer_1.AmbientPlayer.onFrame();
    }
    applyHeightMap() {
        const geo = this._groundPlaneGeo;
        if (!geo.isBufferGeometry) {
            console.error('The geometry must be an instance of THREE.BufferGeometry.');
            return;
        }
        const widthSegments = this._map.heightMap.length - 1; // Number of segments along the width
        const heightSegments = this._map.heightMap[0].length - 1; // Number of segments along the height
        for (let i = 0; i <= widthSegments; i++) {
            for (let j = 0; j <= heightSegments; j++) {
                const vertexIndex = i * (heightSegments + 1) + j;
                geo.attributes.position.setZ(vertexIndex, this._map.heightMap[j][i] * data_1.Global.displacementScale / data_1.Global.heightmapPrecision);
            }
        }
        geo.attributes.position.needsUpdate = true;
        geo.computeVertexNormals();
        geo.attributes.normal.needsUpdate = true;
    }
}
exports.Game = Game;
