"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextureLoader = void 0;
const three_1 = require("three");
const _textureLoader = new three_1.TextureLoader();
const _textures = {};
exports.TextureLoader = {
    loadTextures: (path, ...textures) => __awaiter(void 0, void 0, void 0, function* () {
        for (const texture of textures)
            _textures[texture] = yield _textureLoader.loadAsync(path + texture);
    }),
    getTexture: (texture) => {
        if (!_textures[texture])
            throw new Error(`Texture ${texture} not found.`);
        return _textures[texture];
    },
    getCopyOfTexture: (texture) => {
        if (!_textures[texture])
            throw new Error(`Texture ${texture} not found.`);
        return _textures[texture].clone();
    },
    createNewTextureFromImg: (canvas, texture) => {
        _textures[texture] = new three_1.Texture(canvas);
        _textures[texture].needsUpdate = true;
    },
    setTexture: (name, texture) => {
        _textures[name] = texture;
    },
    reduceColorPalette(texture, paletteSize) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = texture.image.width;
        canvas.height = texture.image.height;
        context.drawImage(texture.image, 0, 0, canvas.width, canvas.height);
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        // Reduce the color palette
        for (let i = 0; i < data.length; i += 4) {
            data[i] = _quantizeColor(data[i], paletteSize); // Red
            data[i + 1] = _quantizeColor(data[i + 1], paletteSize); // Green
            data[i + 2] = _quantizeColor(data[i + 2], paletteSize); // Blue
        }
        // Put the modified data back into the canvas
        context.putImageData(imageData, 0, 0);
        // Create a new texture from the canvas
        const reducedTexture = new three_1.CanvasTexture(canvas);
        reducedTexture.needsUpdate = true;
        return reducedTexture;
    }
};
const _quantizeColor = (value, levels) => Math.round(value / 255 * (levels - 1)) * Math.floor(255 / (levels - 1));
