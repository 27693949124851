"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopUI = void 0;
const three_1 = require("three");
const Items_1 = require("../shared/Items");
const Inventory_1 = require("./Inventory");
const network_1 = require("./network");
const protocoll_1 = require("../shared/protocoll");
const AudioPlayer_1 = require("./AudioPlayer");
const data_1 = require("../shared/data");
const _shopDiv = document.getElementById('shopDiv');
const _shopItemsDiv = document.getElementById('shopItems');
const _shopItemTooltip = document.getElementById('shopItemTooltip');
let _interface = null;
let _inventory = null;
class ShopUI {
    constructor(shopPos, interf, inv) {
        this._div3dPos = new three_1.Vector3();
        this._pos2D = new three_1.Vector2();
        this._shopDivIsVisible = false;
        this._div3dPos.copy(shopPos);
        this._div3dPos.y += 1.5;
        this._pos2D.set(shopPos.x, shopPos.z);
        _interface = interf;
        _inventory = inv;
        ShopUI.refresh();
    }
    static refresh() {
        if (_interface === null || _inventory === null)
            return;
        _shopItemsDiv.innerHTML = '';
        for (const item of Items_1.items) {
            const div = document.createElement('div');
            div.style.backgroundImage = `url('imgs/${item.icon}')`;
            _shopItemsDiv.appendChild(div);
            const lvl = Inventory_1.Inventory.getItemLvl(item) + 1;
            const cost = item.costAtLvl(lvl);
            if (cost > _interface.gold)
                div.classList.add('disabled');
            div.onclick = () => _attemptBuy(item);
            div.onmouseover = () => {
                let title = item.name;
                if (lvl > 1)
                    title += ` (lvl ${lvl})`;
                _shopItemTooltip.innerHTML = `
                    <div class='itemName'>${title}</div>
                    <div class='itemClass'>${item.charges ? "Item" : "Ability"}</div>
                    <div class='cost'>${cost} Gold</div>
                    <div class='itemDescription'>${item.description}</div>
                `;
                _shopItemTooltip.className = `active ${cost > _interface.gold ? 'disabledText' : ''}`;
            };
            div.onmouseout = () => _shopItemTooltip.classList.remove('active');
        }
    }
    onUpdate(sheepPos) {
        const shouldBeVisible = sheepPos.distanceTo(this._pos2D) < data_1.Global.shopPurchaseRange;
        if (shouldBeVisible && !this._shopDivIsVisible) {
            _shopDiv.classList.add('active');
            this._shopDivIsVisible = true;
            _shopItemTooltip.classList.remove('active');
        }
        else if (!shouldBeVisible && this._shopDivIsVisible) {
            _shopDiv.classList.remove('active');
            this._shopDivIsVisible = false;
        }
    }
    onFrame(camera) {
    }
}
exports.ShopUI = ShopUI;
;
const _attemptBuy = (item) => {
    if (_interface === null || _inventory === null)
        return;
    if (item.costAtLvl(Inventory_1.Inventory.getItemLvl(item)) > _interface.gold) {
        _interface.addMsg('Not enough gold!', null, 'red');
        AudioPlayer_1.AudioPlayer.playSound("negative");
        return;
    }
    const msg = {
        input: {
            cmd: protocoll_1.Command.Purchase,
            id: item.id
        }
    };
    network_1.Network.send(msg);
};
