"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FairyRenderer = exports.FairyType = void 0;
const three_1 = require("three");
const functions_1 = require("../shared/functions");
const TextureLoader_1 = require("./TextureLoader");
const data_1 = require("../shared/data");
var FairyType;
(function (FairyType) {
    FairyType["Fairy"] = "fairy";
    FairyType["Snow"] = "snow";
    FairyType["Rain"] = "rain";
    FairyType["Fog"] = "fog";
})(FairyType || (exports.FairyType = FairyType = {}));
;
const _configs = {
    [FairyType.Fairy]: {
        minAlpha: .14,
        maxAlpha: .35,
        count: 40,
        maxSpeed: .05,
        minSpeedY: -.05,
        maxSpeedY: .05,
        scaleMin: .8,
        scaleMax: 2.1,
        textureName: "fairy.png",
        globalSpeed: false,
        dynamicAlphaChange: true,
        randomRotation: true,
        useShape: false
    },
    [FairyType.Snow]: {
        minAlpha: .4,
        maxAlpha: .5,
        count: 250,
        maxSpeed: .02,
        minSpeedY: -.05,
        maxSpeedY: -.03,
        scaleMin: .096,
        scaleMax: .192,
        textureName: "fairy.png",
        globalSpeed: true,
        dynamicAlphaChange: false,
        randomRotation: true,
        useShape: false
    },
    [FairyType.Rain]: {
        minAlpha: .25,
        maxAlpha: .3,
        count: 1000,
        maxSpeed: .01,
        minSpeedY: -.4,
        maxSpeedY: -.35,
        scaleMin: .025,
        scaleMax: .09,
        textureName: "fairy.png",
        globalSpeed: true,
        dynamicAlphaChange: false,
        randomRotation: false,
        useShape: true
    },
    [FairyType.Fog]: {
        minAlpha: .019,
        maxAlpha: .025,
        count: 40,
        maxSpeed: .01,
        minSpeedY: -.01,
        maxSpeedY: .01,
        scaleMin: 2.0,
        scaleMax: 15.0,
        textureName: "fog2.png",
        globalSpeed: false,
        dynamicAlphaChange: false,
        randomRotation: true,
        useShape: false
    }
};
const MAX_GLOBAL_SPEED = .04;
const _rainGeo = new three_1.CylinderGeometry(1, 1, 10, 6);
const _rainPlaneGeo = new three_1.PlaneGeometry(1, 1);
const _globalSpeed = new three_1.Vector3();
class FairyRenderer {
    constructor(scene, type) {
        this._fairies = [];
        this._scene = scene;
        this._config = _configs[type];
    }
    _addFairy(center) {
        const pos = (0, functions_1.randomPointInSphere)(data_1.Global.fairySphereRadius, new three_1.Vector3()).add(center);
        const obj = this._config.useShape ? new three_1.Mesh(_rainPlaneGeo, new three_1.MeshBasicMaterial({
            map: TextureLoader_1.TextureLoader.getTexture(this._config.textureName),
            transparent: true,
            depthWrite: false,
            side: three_1.DoubleSide
        })) : new three_1.Sprite(new three_1.SpriteMaterial({
            map: TextureLoader_1.TextureLoader.getTexture(this._config.textureName),
            transparent: true,
            depthWrite: false,
            color: 0xffffff
        }));
        const material = obj instanceof three_1.Sprite ? obj.material : obj.material;
        const scale = (0, functions_1.rand)(this._config.scaleMin, this._config.scaleMax);
        obj.scale.set(scale, scale, scale);
        if (this._config.randomRotation && material instanceof three_1.SpriteMaterial)
            material.rotation = Math.random() * Math.PI * 2;
        if (obj instanceof three_1.Mesh)
            obj.rotation.y = Math.random() * Math.PI * 2;
        material.opacity = 0.001;
        this._scene.add(obj);
        this._fairies.push({
            pos,
            pos0: pos.clone(),
            vel: new three_1.Vector3((0, functions_1.rand)(-this._config.maxSpeed, this._config.maxSpeed), (0, functions_1.rand)(-this._config.maxSpeed, this._config.maxSpeed), (0, functions_1.rand)(-this._config.maxSpeed, this._config.maxSpeed)),
            alpha: (0, functions_1.rand)(this._config.minAlpha, this._config.maxAlpha),
            obj: obj,
            material: material,
            ageOfLastOpacReset: 99999
        });
    }
    _updateAllFairies(center) {
        for (let i = this._fairies.length - 1; i >= 0; i--) {
            if (!this._updateFairy(this._fairies[i], center))
                this._fairies.splice(i, 1);
        }
    }
    _updateFairy(fairy, center) {
        fairy.pos0.copy(fairy.pos);
        fairy.pos.add(fairy.vel);
        if (this._config.globalSpeed) {
            fairy.pos.x += _globalSpeed.x;
            fairy.pos.z += _globalSpeed.z;
        }
        fairy.vel.x += (0, functions_1.rand)(-0.005, 0.005);
        fairy.vel.y += (0, functions_1.rand)(-0.005, 0.005);
        fairy.vel.z += (0, functions_1.rand)(-0.005, 0.005);
        if (fairy.vel.x > this._config.maxSpeed)
            fairy.vel.x = this._config.maxSpeed;
        if (fairy.vel.z > this._config.maxSpeed)
            fairy.vel.z = this._config.maxSpeed;
        if (fairy.vel.x < -this._config.maxSpeed)
            fairy.vel.x = -this._config.maxSpeed;
        if (fairy.vel.z < -this._config.maxSpeed)
            fairy.vel.z = -this._config.maxSpeed;
        if (fairy.vel.y > this._config.maxSpeedY)
            fairy.vel.y = this._config.maxSpeedY;
        if (fairy.vel.y < this._config.minSpeedY)
            fairy.vel.y = this._config.minSpeedY;
        if (this._config.dynamicAlphaChange) {
            fairy.alpha += (0, functions_1.rand)(-0.05, 0.05);
            if (fairy.alpha < this._config.minAlpha)
                fairy.alpha = this._config.minAlpha;
            else if (fairy.alpha > this._config.maxAlpha)
                fairy.alpha = this._config.maxAlpha;
        }
        fairy.ageOfLastOpacReset++;
        if (fairy.ageOfLastOpacReset < 40)
            fairy.material.opacity = fairy.alpha * fairy.ageOfLastOpacReset / 40;
        else
            fairy.material.opacity = fairy.alpha;
        const dist = fairy.pos.distanceTo(center);
        if (dist > data_1.Global.fairySphereRadius)
            fairy.material.opacity *= Math.max((5 + data_1.Global.fairySphereRadius - dist) / 5, 0);
        if (dist > data_1.Global.fairySphereRadius + 5) {
            (0, functions_1.randomPointInSphere)(data_1.Global.fairySphereRadius, fairy.pos).add(center);
            fairy.pos0.copy(fairy.pos);
            fairy.ageOfLastOpacReset = 0;
        }
        return true;
    }
    _removeFairy() {
        const f = this._fairies.pop();
        if (f !== undefined) {
            this._scene.remove(f.obj);
            f.material.dispose();
        }
    }
    onTick(center) {
        // this._config = _configs.snow;
        this._updateAllFairies(center);
        while (this._fairies.length < this._config.count)
            this._addFairy(center);
        while (this._fairies.length > this._config.count)
            this._removeFairy();
        if (this._config.globalSpeed)
            this._updateGlobalSpeed();
    }
    onFrame(lerpTime) {
        this._renderAllFairies(lerpTime);
    }
    _updateGlobalSpeed() {
        _globalSpeed.x += (0, functions_1.rand)(-0.002, 0.002);
        _globalSpeed.y += (0, functions_1.rand)(-0.002, 0.002);
        _globalSpeed.z += (0, functions_1.rand)(-0.002, 0.002);
        if (_globalSpeed.x > MAX_GLOBAL_SPEED)
            _globalSpeed.x = MAX_GLOBAL_SPEED;
        if (_globalSpeed.z > MAX_GLOBAL_SPEED)
            _globalSpeed.z = MAX_GLOBAL_SPEED;
        if (_globalSpeed.x < -MAX_GLOBAL_SPEED)
            _globalSpeed.x = -MAX_GLOBAL_SPEED;
        if (_globalSpeed.z < -MAX_GLOBAL_SPEED)
            _globalSpeed.z = -MAX_GLOBAL_SPEED;
        if (_globalSpeed.y > MAX_GLOBAL_SPEED)
            _globalSpeed.y = MAX_GLOBAL_SPEED;
        if (_globalSpeed.y < -MAX_GLOBAL_SPEED)
            _globalSpeed.y = -MAX_GLOBAL_SPEED;
    }
    _renderAllFairies(lerpTime) {
        this._fairies.forEach(fairy => {
            fairy.obj.position.x = fairy.pos0.x + (fairy.pos.x - fairy.pos0.x) * lerpTime;
            fairy.obj.position.y = fairy.pos0.y + (fairy.pos.y - fairy.pos0.y) * lerpTime;
            fairy.obj.position.z = fairy.pos0.z + (fairy.pos.z - fairy.pos0.z) * lerpTime;
        });
    }
    dispose() {
        for (const fairy of this._fairies)
            fairy.material.dispose();
    }
}
exports.FairyRenderer = FairyRenderer;
