"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Inventory = void 0;
const Items_1 = require("../shared/Items");
const protocoll_1 = require("../shared/protocoll");
const network_1 = require("./network");
const AudioPlayer_1 = require("./AudioPlayer");
const Shop_1 = require("./Shop");
const data_1 = require("../shared/data");
const _div = document.getElementById("inventory");
const _inventoryTooltip = document.getElementById("inventoryTooltip");
let _interface = null;
let _activeSlot = null;
const _items = [];
class Inventory {
    constructor(interf) {
        _interface = interf;
    }
    static getItemLvl(type) {
        const item = _items.find(i => i.type === type);
        return item !== undefined ? item.lvl : 0;
    }
    onTick() {
        for (const i of _items) {
            if (i.cooldown > 0)
                this._setCooldown(i, i.cooldown - 1);
        }
    }
    addItem(data) {
        AudioPlayer_1.AudioPlayer.playSound("buildingfinished");
        const type = Items_1.items.find(t => t.id === data.id);
        const div = document.createElement("div");
        div.className = "inventoryButton";
        div.onclick = () => _attemptUseItem(item);
        div.onmouseover = () => _showTooltip(type);
        div.onmouseleave = _hideTooltip;
        div.style.backgroundImage = "url('imgs/" + type.icon + "')";
        const cooldownDiv = document.createElement("div");
        cooldownDiv.className = "cooldown";
        cooldownDiv.style.display = "none";
        div.appendChild(cooldownDiv);
        const chargesDiv = document.createElement("div");
        chargesDiv.className = "charges";
        div.appendChild(chargesDiv);
        const lvlDiv = document.createElement("div");
        lvlDiv.className = "lvl";
        div.appendChild(lvlDiv);
        const item = {
            div,
            cooldownDiv,
            chargesDiv,
            lvlDiv,
            type,
            charges: 0,
            cooldown: 0,
            lvl: 0
        };
        this._setCharges(item, data.charges);
        this._setLvl(item, data.lvl);
        _items.push(item);
        const sortedItems = _items.sort((a, b) => a.type.nr - b.type.nr);
        for (const item of _items) {
            if (item.div.parentElement === _div)
                _div.removeChild(item.div);
        }
        for (const item of sortedItems)
            _div.appendChild(item.div);
    }
    setChargesFromItemId(id, charges) {
        const item = _items.find(i => i.type.id === id);
        if (item !== undefined) {
            if (charges > item.charges)
                AudioPlayer_1.AudioPlayer.playSound("buildingfinished");
            this._setCharges(item, charges);
        }
        Shop_1.ShopUI.refresh();
    }
    setLvlFromItemId(id, lvl) {
        AudioPlayer_1.AudioPlayer.playSound("buildingfinished");
        const item = _items.find(i => i.type.id === id);
        if (item !== undefined)
            this._setLvl(item, lvl);
        Shop_1.ShopUI.refresh();
    }
    setCooldownFromItemId(id, cooldown) {
        const item = _items.find(i => i.type.id === id);
        if (item !== undefined)
            this._setCooldown(item, cooldown);
    }
    _setCharges(invItem, charges) {
        if (charges <= 0 && invItem.type.charges !== undefined) {
            _items.splice(_items.indexOf(invItem), 1);
            _div.removeChild(invItem.div);
            return;
        }
        invItem.charges = charges;
        invItem.chargesDiv.innerText = charges.toString();
        invItem.chargesDiv.style.display = charges > 0 ? "block" : "none";
    }
    _setLvl(invItem, lvl) {
        invItem.lvl = lvl;
        let str = "";
        if (lvl > 0) {
            for (let i = 0; i < lvl; i++)
                str += "&starf;";
        }
        invItem.lvlDiv.innerHTML = str;
        // invItem.lvlDiv.style.display = lvl > 0 ? "block" : "none";
    }
    _setCooldown(invItem, cooldown) {
        invItem.cooldown = cooldown;
        _refreshButtonCooldown(invItem.cooldownDiv, cooldown / invItem.type.cooldownAtLvl(invItem.lvl));
    }
    onLeftClick(field) {
        if (_activeSlot !== null) {
            const msg = {
                input: {
                    cmd: protocoll_1.Command.UseItem,
                    id: _activeSlot.type.id,
                    pos: [{
                            x: field.x,
                            y: field.z
                        }]
                }
            };
            network_1.Network.send(msg);
            _activeSlot = null;
        }
    }
    onRightClick() {
        if (_activeSlot !== null) {
            _activeSlot = null;
            return false;
        }
        return true;
    }
    getActiveSlot() {
        return _activeSlot;
    }
}
exports.Inventory = Inventory;
const _attemptUseItem = (item) => {
    if (_interface === null)
        return;
    if (item.cooldown > 0) {
        _interface.addMsg("Cooldown not ready", null, "red");
        AudioPlayer_1.AudioPlayer.playSound("negative");
        return;
    }
    if (item.type.targetIsPoint) {
        _activeSlot = item;
        return;
    }
    if (item.type.heals && _interface.hasFullHp()) {
        _interface.addMsg("You have full health", null, "red");
        AudioPlayer_1.AudioPlayer.playSound("negative");
        return;
    }
    const msg = {
        input: {
            cmd: protocoll_1.Command.UseItem,
            id: item.type.id
        }
    };
    network_1.Network.send(msg);
};
const _showTooltip = (type) => {
    let title = type.name;
    const lvl = Inventory.getItemLvl(type);
    if (lvl > 1)
        title += ` (lvl ${lvl})`;
    _inventoryTooltip.innerHTML = `
        <div id="inventoryTooltipTitle">${title}</div>
        <div class="inventoryTooltipText">${type.description}</div>
    `;
    if (type.cooldownAtLvl !== undefined) {
        const cooldown = type.cooldownAtLvl(lvl) / data_1.Global.ticksPerSec;
        if (cooldown > 1)
            _inventoryTooltip.innerHTML += `<div class="inventoryTooltipStat">Cooldown: ${cooldown.toFixed(1)} sec</div>`;
    }
    if (type.durationAtLvl !== undefined)
        _inventoryTooltip.innerHTML += `<div class="inventoryTooltipStat">Duration: ${(type.durationAtLvl(lvl) / data_1.Global.ticksPerSec).toFixed(1)} sec</div>`;
    if (type.tpDistanceAtLvl !== undefined)
        _inventoryTooltip.innerHTML += `<div class="inventoryTooltipStat">Range: ${type.tpDistanceAtLvl(lvl).toFixed(1)}</div>`;
    if (type.speedMultiplierAtLvl !== undefined)
        _inventoryTooltip.innerHTML += `<div class="inventoryTooltipStat">Speed: +${Math.round(type.speedMultiplierAtLvl(lvl) * 100 - 100)}%</div>`;
    _inventoryTooltip.classList.add("active");
};
const _hideTooltip = () => _inventoryTooltip.classList.remove("active");
const _refreshButtonCooldown = (div, perc) => {
    perc = 1 - perc;
    if (perc >= 1) {
        div.style.display = "none";
    }
    else {
        div.style.display = "";
        let path = "polygon(50% 0%, 50% 50%";
        if (perc <= .125) {
            const val = 50 + Math.tan(Math.PI * perc * 2) * 50;
            path += ", " + val + "% 0%";
            path += ", 100% 0%, 100% 100%, 0% 100%, 0% 0%";
        }
        else if (perc <= .375) {
            const val = Math.tan(Math.PI * (perc - .25) * 2) * 50 + 50;
            path += ", 100% " + val + "%";
            path += ", 100% 100%, 0% 100%, 0% 0%";
        }
        else if (perc <= .625) {
            const val = Math.tan(Math.PI * -(perc - .5) * 2) * 50 + 50;
            path += ", " + val + "% 100%";
            path += ", 0% 100%, 0% 0%";
        }
        else if (perc <= .875) {
            const val = Math.tan(Math.PI * -(perc - .75) * 2) * 50 + 50;
            path += ", 0% " + val + "%";
            path += ", 0% 0%";
        }
        else {
            const val = Math.tan(Math.PI * (perc - .875) * 2) * 50;
            path += ", " + val + "% 0%";
        }
        div.style.clipPath = path + ")";
    }
};
