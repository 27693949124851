"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AStar = void 0;
const three_1 = require("three");
const functions_1 = require("./functions");
const _vec2_0 = new three_1.Vector2();
const _vec2_1 = new three_1.Vector2();
const _vec2_2 = new three_1.Vector2();
class AStar {
    constructor(grid) {
        this._grid = [];
        this._originalGrid = [];
        this._originalGrid = grid;
        for (let x = 0; x < grid.length; x++) {
            this._grid[x] = [];
            for (let y = 0; y < grid[x].length; y++) {
                this._grid[x][y] =
                    {
                        x: x + .5,
                        y: y + .5,
                        nbs: [],
                        pathing: grid[x][y]
                    };
            }
        }
        for (let x = 0; x < grid.length; x++) {
            for (let y = 0; y < grid[x].length; y++) {
                const field = this._grid[x][y];
                field.nbs.length = 0;
                for (let x2 = x - 1; x2 <= x + 1; x2++) {
                    for (let y2 = y - 1; y2 <= y + 1; y2++) {
                        if ((x !== x2 || y !== y2) && x2 in grid && y2 in grid[x2] && grid[x2][y2] === 0 && (x2 === x || y2 === y || (grid[x][y2] === 0 && grid[x2][y] === 0)))
                            field.nbs.push(this._grid[x2][y2]);
                    }
                }
            }
        }
    }
    getPath(from, to) {
        const openList = [];
        const closedList = [];
        let exactTarget = (0, functions_1.getNextFreePositionFrom)(to, this._originalGrid, _vec2_0);
        let exactStart = from.clone();
        if (exactTarget === null)
            return [exactStart];
        const startNode = this._grid[Math.floor(exactStart.x)][Math.floor(exactStart.y)];
        let targetNode = this._grid[Math.floor(exactTarget.x)][Math.floor(exactTarget.y)];
        if (targetNode === startNode)
            return [exactTarget];
        // insert Start Node as closed and nB's as open
        openList.push(startNode);
        startNode.g = 0;
        startNode.h = _distance(startNode, targetNode);
        startNode.f = startNode.h;
        startNode.isOnOpenList = true;
        while (true) {
            // if open list empty => target not reachable
            if (openList.length === 0) {
                // find closest Field from closedList, put it back on openList, so it will be found as next Field to check
                let closestIndex = 0;
                for (let i = 0; i < closedList.length; i++) {
                    if (_distance(closedList[i], targetNode) < _distance(closedList[closestIndex], targetNode))
                        closestIndex = i;
                }
                openList.push(closedList[closestIndex]);
                targetNode = closedList[closestIndex];
                exactTarget = new three_1.Vector2(targetNode.x, targetNode.y);
            }
            // get 'best' field from OpenList
            let currentNodeIndex = 0;
            for (let i = 1; i < openList.length; i++)
                if (openList[i].f < openList[currentNodeIndex].f)
                    currentNodeIndex = i;
            const currentNode = openList[currentNodeIndex];
            for (const nb of currentNode.nbs) {
                // if node already closed, skip
                if (nb.closed)
                    continue;
                if (!nb.isOnOpenList) // if the field is not on the list yet, add it
                 {
                    openList.push(nb);
                    nb.isOnOpenList = true;
                    nb.parent = currentNode;
                    nb.g = currentNode.g + _distance(nb, currentNode);
                    nb.h = _distance(nb, targetNode);
                    nb.f = nb.g + nb.h;
                }
                else {
                    const new_g = currentNode.g + _distance(currentNode, nb);
                    if (new_g < nb.g) // new way to tempField is better than the existing one
                     {
                        nb.g = new_g;
                        nb.f = new_g + nb.h;
                        nb.parent = currentNode; // set this Field as new parent, because it offers the best way
                    }
                }
            }
            // move field from open to closed list
            openList.splice(currentNodeIndex, 1);
            closedList.push(currentNode);
            currentNode.isOnOpenList = false;
            currentNode.closed = true;
            if (currentNode === targetNode) // we have found the best way
             {
                const grids = [targetNode];
                while (grids[grids.length - 1] && grids[grids.length - 1].parent !== startNode && grids[grids.length - 1].parent && grids.length < 500)
                    grids.push(grids[grids.length - 1].parent);
                const path = grids;
                // add exact target as final target, if passable
                if ((0, functions_1.unitCouldStandAt)(exactTarget, this._originalGrid))
                    path.splice(0, 0, exactTarget);
                // path smoothing
                for (let i = path.length - 2; i >= 0; i--) {
                    if ((0, functions_1.raycast)(exactStart, path[i], this._originalGrid, _vec2_2).equals(path[i]))
                        path.splice(i + 1, 1);
                    else
                        exactStart = path[i + 1];
                }
                // clear all the fields 'isOnOpenList' & 'closed' values
                const usedFields = openList.concat(closedList);
                for (const f of usedFields) {
                    f.isOnOpenList = false;
                    f.closed = false;
                }
                return path;
            }
        }
    }
}
exports.AStar = AStar;
const _distance = (a, b) => Math.sqrt(Math.pow((a.x - b.x), 2) + Math.pow((a.y - b.y), 2));
