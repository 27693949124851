"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendInitGrid = exports.sendVisionRequest = void 0;
const data_1 = require("../shared/data");
let _game = null;
const _visionWorker = new Worker("dist/visionWorker.bundle.js");
_visionWorker.addEventListener('message', function (e) {
    if (_game !== null)
        _game.updateVisionCanvas(e.data.paths);
}, false);
const sendVisionRequest = (game) => {
    _game = game;
    const sheep = game.getVisionSheep();
    const units = [];
    for (const s of sheep) {
        units.push({
            x: s.drawPos.x,
            y: s.drawPos.y,
            v: data_1.Global.sheepVisionRange
        });
    }
    ;
    const msg = {
        units
    };
    _visionWorker.postMessage(msg);
};
exports.sendVisionRequest = sendVisionRequest;
const sendInitGrid = (game) => {
    const msg = {
        grid: game.grid
    };
    _visionWorker.postMessage(msg);
};
exports.sendInitGrid = sendInitGrid;
