"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Minimap = void 0;
const three_1 = require("three");
const tiles_1 = require("../shared/tiles");
const ImgLoader_1 = require("./ImgLoader");
const _canv1 = document.getElementById("minimapCanvas1");
const _canv2 = document.getElementById("minimapCanvas2");
const _ctx1 = _canv1.getContext("2d");
const _ctx2 = _canv2.getContext("2d");
const _color = new three_1.Color();
const _scale = 4;
class Minimap {
    constructor(map) {
        this._scannedPositions = null;
        this._scanDuration = -9999;
        _canv1.width = map.x * _scale;
        _canv1.height = map.y * _scale;
        _canv2.width = map.x * _scale;
        _canv2.height = map.y * _scale;
        const avgColor = ImgLoader_1.ImgLoader.getAVGColor(ImgLoader_1.ImgLoader.getImg(map.groundTexture));
        _ctx1.fillStyle = avgColor.getStyle();
        _ctx1.fillRect(0, 0, _canv1.width, _canv1.height);
        _ctx1.globalAlpha = .75;
        for (const t of map.tiles) {
            const type = tiles_1.tileTypes.find(tt => tt.id === t.type);
            if (type !== undefined && type.color !== undefined) {
                _ctx1.fillStyle = _color.set(type.color).getStyle();
                _ctx1.fillRect((t.x - 1) * _scale, (t.z - 1) * _scale, type.w * _scale, type.h * _scale);
                // _ctx1.beginPath();
                // _ctx1.arc((t.x - type.w * .5) * _scale, (t.y - type.h * .5) * _scale, _scale * type.w * .75, 0, Math.PI * 2);
                // _ctx1.fill();
            }
        }
    }
    onScan(positions) {
        this._scannedPositions = positions;
        this._scanDuration = 20;
    }
    onTick() {
        if (this._scanDuration > 0) {
            this._scanDuration--;
            if (this._scanDuration <= 0)
                this._scannedPositions = null;
        }
    }
    onFrame(visionCanvas, playerPos) {
        _ctx2.clearRect(0, 0, _canv2.width, _canv2.height);
        _ctx2.drawImage(visionCanvas, 0, 0, _canv2.width, _canv2.height);
        _ctx2.fillStyle = "rgb(255, 255, 255)";
        if (playerPos !== undefined)
            _ctx2.fillRect((playerPos.x - .5) * _scale, (playerPos.y - .5) * _scale, _scale, _scale);
        if (this._scannedPositions !== null) {
            _ctx2.fillStyle = "rgb(255, 0, 0)";
            for (const pos of this._scannedPositions)
                _ctx2.fillRect((pos.x - .5) * _scale, (pos.y - .5) * _scale, _scale, _scale);
        }
    }
}
exports.Minimap = Minimap;
;
