"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpriteEffect = void 0;
const three_1 = require("three");
class SpriteEffect {
    constructor(config, scene, ticksCounter) {
        var _a, _b, _c, _d;
        this._origin = new three_1.Vector3();
        this._velocity = new three_1.Vector3();
        this._scene = scene;
        this._sprite = config.sprite;
        this._sprite.position.copy(config.pos);
        scene.add(this._sprite);
        this._textures = config.textures;
        if (config.scale)
            this._sprite.scale.set(config.scale, config.scale * ((_a = config.scaleY) !== null && _a !== void 0 ? _a : 1), config.scale);
        if (config.rotation && this._sprite.material instanceof three_1.SpriteMaterial)
            this._sprite.material.rotation = config.rotation;
        if (config.color)
            this._sprite.material.color.copy(config.color);
        this._origin.copy(config.pos);
        this._velocity.copy(config.velocity);
        this._moveFunc = config.moveFunc;
        this._alphaFunc = config.alphaFunc;
        this._scaleFunc = config.scaleFunc;
        this._yFunc = config.yFunc;
        this.r0 = config.r0;
        this.r1 = config.r1;
        this.r2 = config.r2;
        this.r3 = config.r3;
        this.r4 = config.r4;
        this._scale = (_b = config.scale) !== null && _b !== void 0 ? _b : 1;
        this._scaleY = (_c = config.scaleY) !== null && _c !== void 0 ? _c : 1;
        this._tickOfCreation = ticksCounter;
        this.timeToLive = (_d = config.timeToLive) !== null && _d !== void 0 ? _d : 1;
        this.animation = config.animation;
        if (this.animation && this._sprite instanceof three_1.Sprite) {
            this._sprite.material.map.wrapS = three_1.RepeatWrapping;
            this._sprite.material.map.wrapT = three_1.RepeatWrapping;
            this._sprite.material.map.repeat.set(1 / this.animation.frameCountX, 1 / this.animation.frameCountY);
            this.timeToLive = this.animation.frameCountX * this.animation.frameCountY * this.animation.frameDuration;
        }
    }
    render(exactTicks) {
        const age = (exactTicks - this._tickOfCreation) / 20;
        if (this._moveFunc) {
            const val = this._moveFunc(age, this);
            this._sprite.position.x = this._origin.x + val * this._velocity.x;
            this._sprite.position.y = this._origin.y + val * this._velocity.y;
            this._sprite.position.z = this._origin.z + val * this._velocity.z;
        }
        if (this._yFunc)
            this._sprite.position.y += this._yFunc(age, this);
        if (this._alphaFunc)
            this._sprite.material.opacity = this._alphaFunc(age, this);
        if (this._scaleFunc) {
            const val = this._scaleFunc(age, this);
            this._sprite.scale.set(val * this._scale, val * this._scale * this._scaleY, val * this._scale);
        }
        if (this.animation && this._sprite instanceof three_1.Sprite) {
            const frame = Math.floor(age / this.animation.frameDuration) % (this.animation.frameCountX * this.animation.frameCountY);
            const x = frame % this.animation.frameCountX;
            const y = Math.floor(frame / this.animation.frameCountX);
            this._sprite.material.map.offset.x = x / this.animation.frameCountX;
            this._sprite.material.map.offset.y = (-1 - y) / this.animation.frameCountY;
        }
        if (this._textures !== undefined) {
            const frame = Math.floor(age / this.timeToLive * this._textures.length);
            this._sprite.material.map = this._textures[frame];
            this._sprite.material.needsUpdate = true;
        }
        if (age < this.timeToLive) {
            return true;
        }
        else {
            this._scene.remove(this._sprite);
            return false;
        }
    }
}
exports.SpriteEffect = SpriteEffect;
