"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Command = void 0;
var Command;
(function (Command) {
    Command[Command["None"] = 0] = "None";
    Command[Command["Move"] = 1] = "Move";
    Command[Command["Attack"] = 2] = "Attack";
    Command[Command["Purchase"] = 3] = "Purchase";
    Command[Command["UseItem"] = 4] = "UseItem";
})(Command || (exports.Command = Command = {}));
;
