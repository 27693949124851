"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpriteEffectsRenderer = void 0;
const three_1 = require("three");
const SpriteEffect_1 = require("./SpriteEffect");
const TextureLoader_1 = require("./TextureLoader");
const functions_1 = require("../shared/functions");
const RessourcePool_1 = require("./RessourcePool");
const ParticleEffect_1 = require("./ParticleEffect");
const _vec3_0 = new three_1.Vector3();
class SpriteEffectsRenderer {
    constructor(scene) {
        this._spriteEffects = [];
        this._channelEffects = [];
        this._scene = scene;
    }
    onTick(ticksCounter) {
        for (let i = this._channelEffects.length - 1; i >= 0; i--) {
            const effect = this._channelEffects[i];
            effect.age++;
            if (effect.age > effect.ticksToLive) {
                this._channelEffects.splice(i, 1);
                continue;
            }
            let particlesToAdd = 5;
            while (particlesToAdd > 0) {
                if (Math.random() < particlesToAdd) {
                    const sprite = RessourcePool_1.RessourcePool.getSprite("fullCircle.png", 1, Math.random(), 1);
                    this.createSprite({
                        pos: effect.unitPos.clone().add((0, functions_1.randOnDisc)(_vec3_0).multiplyScalar(.75)).setY(effect.unitPos.y + (0, functions_1.rand)(0, .4)),
                        sprite,
                        velocity: new three_1.Vector3(0, (0, functions_1.rand)(.6, 1.3), 0),
                        scale: (0, functions_1.rand)(.08, .12),
                        moveFunc: (age) => age * .9,
                        alphaFunc: function (age) { return Math.min((1 - age / this.timeToLive) * 1.5, 1); },
                        timeToLive: .6
                    }, ticksCounter);
                }
                particlesToAdd--;
            }
        }
    }
    createUnitEffect(pos, ticksCounter, textureName, color) {
        this.createSprite({
            pos: pos.clone().add((0, functions_1.randOnDisc)(_vec3_0).multiplyScalar(.5)).setY(pos.y + (0, functions_1.rand)(0, .4)),
            sprite: RessourcePool_1.RessourcePool.getSprite(textureName, color),
            velocity: new three_1.Vector3(0, (0, functions_1.rand)(.6, 1.3), 0),
            scale: (0, functions_1.rand)(.2, .3),
            moveFunc: (age) => age * .9,
            alphaFunc: function (age) { return Math.min((1 - age / this.timeToLive) * 1.5, 1); },
            scaleFunc: function (age) { return (Math.pow((age * 8 - 1), 4) - 1) / (Math.pow((age * 8 - 1), 5) - 1); },
            timeToLive: .6
        }, ticksCounter);
    }
    reset() {
        this._spriteEffects.length = 0;
        this._channelEffects.length = 0;
    }
    onFrame(exactTicks, lerpTime) {
        for (let i = 0; i < this._spriteEffects.length; i++) {
            if (!this._spriteEffects[i].render(exactTicks)) {
                this._spriteEffects.splice(i, 1);
                i--;
            }
        }
    }
    makeImpactSprite(pos, ticksCounter, scale = 1.2, color = new three_1.Color(0xfff6a9)) {
        const textures = ParticleEffect_1.ParticleEffect.getRandom("impact");
        this.createSprite({
            pos,
            sprite: new three_1.Sprite(new three_1.SpriteMaterial({ depthWrite: false, transparent: true, opacity: .9, map: textures[0], color })),
            scale,
            textures,
            velocity: new three_1.Vector3(),
            timeToLive: .3
        }, ticksCounter);
    }
    makeSpellChannelEffect(unitPos) {
        this._channelEffects.push({
            unitPos,
            age: 0,
            ticksToLive: 10
        });
    }
    massSmoke(pos, radius, ticksCounter, count, additionalY) {
        for (let i = 0; i < (count !== null && count !== void 0 ? count : 20); i++) {
            const a = Math.random() * Math.PI * 2;
            this._spriteEffects.push(new SpriteEffect_1.SpriteEffect({
                pos,
                sprite: new three_1.Sprite(new three_1.SpriteMaterial({ depthWrite: false, transparent: true, opacity: .9, map: TextureLoader_1.TextureLoader.getTexture("smoke.png") })),
                velocity: _vec3_0.set(Math.cos(a), additionalY !== null && additionalY !== void 0 ? additionalY : 0, Math.sin(a)).normalize(),
                r1: radius,
                moveFunc: (age, sprite) => (-(1 / (15 * age + 1.2)) + 1) * sprite.r1,
                scale: radius * (0, functions_1.rand)(.5, 1.5),
                alphaFunc: (age, sprite) => (1 - age / sprite.timeToLive) * .66,
                rotation: Math.random() * Math.PI * 2,
                timeToLive: (0, functions_1.rand)(1.5, 2.5)
            }, this._scene, ticksCounter));
        }
    }
    makeUnitDeathEffect(originalPos, radius, ticksCounter, color) {
        const pos = originalPos.clone();
        const strongWhite = new three_1.Color(2, 2, 2);
        this.massSmoke3d(pos, radius * 1.3, ticksCounter, 6, "flame.png", strongWhite, .2, .3, radius * 1.2, radius * 1.6, 1);
        this.massSmoke3d(pos, radius * 2.1, ticksCounter, 20, "flame.png", color, .8, 1.2, radius * .9, radius * 1.1, .8);
        this.makeImpactSprite(originalPos, ticksCounter, radius * 4, new three_1.Color(8, 8, 8));
    }
    createBlinkEffect(p, ticksCounter) {
        /*
        for( let i = 0; i < 1; i++ )
        {
            const randomAngle = rand( 0, Math.PI * 2 );
            const randomDist = rand( 0, .4 );

            this._spriteEffects.push(new SpriteEffect({
                pos: new Vector3( p.x + Math.sin( randomAngle ) * randomDist, p.y + rand( 0, 2 ), p.z + Math.cos( randomAngle ) * randomDist ),
                velocity: randomPointOnSphere( rand( 0, 1 ), new Vector3()),
                timeToLive: 2,
                sprite: new Sprite( new SpriteMaterial({
                    transparent: true,
                    depthWrite: false,
                    map: TextureLoader.getTexture( "tp.png" )
                })),
                scale: rand( 1.5, 2.5 ),
                alphaFunc: function( this: SpriteEffect, age: number )
                {
                    return Math.max( -Math.pow( this.r1! * age - this.r2!, 4 ) + 1, 0 );
                },
                moveFunc: age => age,
                r1: rand( 6, 9 ),
                r2: rand( 1, 1.6 ),
                r3: rand( 0, 999 ),
                r4: rand( -3, 3 )
            }, this._scene, ticksCounter ));
        }
        */
        for (let i = 0; i < 12; i++) {
            const randomAngle = (0, functions_1.rand)(0, Math.PI * 2);
            const randomDist = (0, functions_1.rand)(0, .3);
            this._spriteEffects.push(new SpriteEffect_1.SpriteEffect({
                pos: new three_1.Vector3(p.x + Math.sin(randomAngle) * randomDist, p.y + (0, functions_1.rand)(0, 0.5), p.z + Math.cos(randomAngle) * randomDist),
                velocity: (0, functions_1.randomPointOnSphere)((0, functions_1.rand)(0, 1), new three_1.Vector3()),
                timeToLive: 2,
                sprite: RessourcePool_1.RessourcePool.getSprite("tp.png", 1, 1, 1),
                scale: (0, functions_1.rand)(.3, .5),
                alphaFunc: function (age) {
                    return (0, functions_1.seededRand)(Math.floor(age * 20) + this.r3) < .33 ? 0 : 1;
                },
                moveFunc: age => age,
                scaleFunc: function (age) {
                    return Math.max(-Math.pow(this.r1 * age - this.r2, 8) + 1, 0);
                },
                r1: (0, functions_1.rand)(3, 6),
                r2: (0, functions_1.rand)(1, 1.6),
                r3: (0, functions_1.rand)(0, 999),
                rotation: Math.random() < .5 ? 0 : Math.PI
            }, this._scene, ticksCounter));
        }
    }
    massSmoke3d(pos, radius, ticksCounter, count, textureName, color, ttlMin, ttlMax, scaleMin, scaleMax, alpha) {
        for (let i = 0; i < count; i++) {
            this._spriteEffects.push(new SpriteEffect_1.SpriteEffect({
                pos,
                sprite: RessourcePool_1.RessourcePool.getSprite(textureName, color),
                velocity: (0, functions_1.randomPointOnSphere)(radius, _vec3_0),
                moveFunc: (age) => (-(1 / (12 * age + 1.2)) + 1),
                scale: (0, functions_1.rand)(scaleMin, scaleMax),
                r0: alpha,
                alphaFunc: function (age) { return (1 - age / this.timeToLive) * this.r0; },
                rotation: Math.random() * Math.PI * 2,
                timeToLive: (0, functions_1.rand)(ttlMin, ttlMax)
            }, this._scene, ticksCounter));
        }
    }
    createSprite(config, ticksCounter) {
        this._spriteEffects.push(new SpriteEffect_1.SpriteEffect(config, this._scene, ticksCounter));
    }
}
exports.SpriteEffectsRenderer = SpriteEffectsRenderer;
