"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Global = exports.DEBUG = void 0;
exports.DEBUG = false;
exports.Global = {
    ticksPerSec: 20,
    sheepBaseSpeed: exports.DEBUG ? .266 : .1,
    sheepBaseHealth: 100,
    sheepBaseDamage: 30,
    sheepBaseRange: 5,
    sheepBaseDmgPoint: 4,
    sheepBaseAttackCooldown: 26,
    sheepRadius: .35,
    sheepRadiusTimes2: .8,
    sheepVisionRange: 8,
    sheepVisionRangeSquared: 64,
    leapRange: 1.0,
    projectileRadius: .2,
    projectileSpeed: .2,
    fieldSize: 64,
    visionFieldSize: 32,
    goldPerNeutralSheep: 20,
    goldPerPlayerSheep: 200,
    sheepCount: 150,
    fairySphereRadius: 10,
    displacementScale: 6.6,
    initGold: 1000,
    neutralSheepRespawnInterval: 100,
    heightmapPrecision: 1000,
    mapTopDeadZone: 24,
    mapSideDeadZone: 24,
    mapBottomDeadZone: 24,
    shopPurchaseRange: 5,
    fountainRegenRate: 10 / 20,
    potionHealingRate: 12 / 20,
    fountainRange: 5,
    respawnTime: 10 * 20
};
exports.Global.sheepVisionRangeSquared = exports.Global.sheepVisionRange * exports.Global.sheepVisionRange;
exports.Global.sheepRadiusTimes2 = exports.Global.sheepRadius * 2;
