"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.get2DPosition = exports.getGroundHeightAtPosition = exports.drawText = exports.drawCircle = void 0;
const three_1 = require("three");
const data_1 = require("../shared/data");
const _vec3_0 = new three_1.Vector3();
const _vec3_1 = new three_1.Vector3();
const drawCircle = (ctx, x, y, size, color, fillColor, lineWidth) => {
    const yScale_ = .7;
    ctx.scale(1, yScale_);
    ctx.lineWidth = lineWidth !== null && lineWidth !== void 0 ? lineWidth : 1;
    ctx.beginPath();
    ctx.arc(x, y / yScale_, size, 0, 2 * Math.PI, false);
    if (color) {
        ctx.strokeStyle = color;
        ctx.stroke();
    }
    if (fillColor) {
        ctx.fillStyle = fillColor;
        ctx.fill();
    }
    ctx.scale(1, 1 / yScale_);
};
exports.drawCircle = drawCircle;
const drawText = (ctx, text, color, size, font, x, y, w, align, alpha, fillStyle, shadowStyle, height) => {
    let text2 = text;
    const w2 = w ? w : 99999;
    alpha = alpha ? alpha : 1;
    height = height || 0;
    let returnValue = 1; // number of lines we used to draw the text
    ctx.font = "bold " + size + "px " + font;
    ctx.textAlign = (align ? align : "left");
    // check if text fits in line, if not, recursively call for next line
    if (ctx.measureText(text).width > w2) {
        const words = text.split(" ");
        let line = words[0];
        let lastFittingLine;
        let i = 1;
        while (ctx.measureText(line).width <= w2 && i < words.length) {
            lastFittingLine = line;
            line = line + " " + words[i];
            i++;
        }
        text2 = lastFittingLine ? lastFittingLine : text;
        words.splice(0, Math.max(i - 1, 1));
        if (words.length > 0)
            returnValue += (0, exports.drawText)(ctx, words.join(" "), color, size, font, x, y + height + 4, w2, align, alpha, fillStyle, shadowStyle, height);
    }
    const textWidth = ctx.measureText(text2).width;
    // fillrect, if fillstyle parameter passed
    if (fillStyle && height !== undefined) {
        ctx.globalAlpha = alpha;
        ctx.fillStyle = fillStyle;
        ctx.fillRect(x - (align === "center" ? (textWidth + 6) / 2 : 0), y - height * 0.9, textWidth + 6, height * 1.1);
    }
    ctx.strokeStyle = "black";
    ctx.lineWidth = size / 22;
    ctx.strokeText(text2, x, y);
    ctx.fillStyle = color;
    ctx.fillText(text2, x, y);
    ctx.globalAlpha = 1;
    return returnValue;
};
exports.drawText = drawText;
const getGroundHeightAtPosition = (x, z, heightMap) => {
    const w = heightMap.length - 1;
    const h = heightMap[0].length - 1;
    const u = Math.min(Math.max((x + data_1.Global.mapSideDeadZone) / w, 0), 1);
    const v = Math.min(Math.max((z + data_1.Global.mapTopDeadZone) / h, 0), 1);
    const gridX = u * w;
    const gridZ = v * h;
    const x1 = Math.floor(gridX);
    const x2 = Math.min(x1 + 1, heightMap.length - 1);
    const z1 = Math.floor(gridZ);
    const z2 = Math.min(z1 + 1, heightMap[0].length - 1);
    const q11 = heightMap[x1][z1];
    const q12 = heightMap[x1][z2];
    const q21 = heightMap[x2][z1];
    const q22 = heightMap[x2][z2];
    const tx = gridX - x1;
    const tz = gridZ - z1;
    const lerpX1 = q11 * (1 - tx) + q21 * tx;
    const lerpX2 = q12 * (1 - tx) + q22 * tx;
    const interpolatedHeight = lerpX1 * (1 - tz) + lerpX2 * tz;
    return interpolatedHeight * data_1.Global.displacementScale / data_1.Global.heightmapPrecision;
};
exports.getGroundHeightAtPosition = getGroundHeightAtPosition;
const get2DPosition = (vector3, camera) => {
    const vector = _vec3_1.copy(vector3);
    vector.project(camera);
    // convert from NDC (-1 to 1) to range 0 to 1
    vector.x = (vector.x + 1) / 2;
    vector.y = -(vector.y - 1) / 2;
    // multiply by canvas size to get pixel coordinates
    vector.x = vector.x * window.innerWidth;
    vector.y = vector.y * window.innerHeight;
    return vector;
};
exports.get2DPosition = get2DPosition;
