"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AmbientPlayer = void 0;
const functions_1 = require("../shared/functions");
const AudioLoader_1 = require("./AudioLoader");
const AudioPlayer_1 = require("./AudioPlayer");
let _bgNoise = null;
let _nextBirdSound = null;
exports.AmbientPlayer = {
    onFrame: () => {
        if (_bgNoise === null) {
            _bgNoise = AudioLoader_1.soundLinks.background[0];
            _bgNoise.loop = true;
            _bgNoise.volume = .25;
            _bgNoise.play();
        }
        if (_nextBirdSound === null)
            _nextBirdSound = Date.now() + (0, functions_1.rand)(2000, 25000);
        if (_nextBirdSound < Date.now()) {
            const birdSound = AudioPlayer_1.AudioPlayer.playSound("bird1");
            birdSound.playbackRate = (0, functions_1.rand)(.5, 1.5);
            birdSound.volume = (0, functions_1.rand)(.1, .5);
            _nextBirdSound = Date.now() + (0, functions_1.rand)(2000, 35000);
        }
    }
};
