"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tile = void 0;
const three_1 = require("three");
const ModelLoader_1 = require("./ModelLoader");
const functions_1 = require("./functions");
const functions_2 = require("../shared/functions");
const ImgLoader_1 = require("./ImgLoader");
const data_1 = require("../shared/data");
const RessourcePool_1 = require("./RessourcePool");
const _planeGeo = new three_1.PlaneGeometry(1, 1);
const _vec3_0 = new three_1.Vector3();
const _quat_0 = new three_1.Quaternion();
const _quat_1 = new three_1.Quaternion();
const _eul_0 = new three_1.Euler();
class Tile {
    constructor(type, x, y, z, heightMap, groundCtx, scene, scale, rotation) {
        var _a, _b;
        this.collider = new three_1.Box3();
        this._currentOpacity = 1;
        this._isPlane = false;
        this.type = type;
        this.x = x;
        this.z = z;
        this._mesh = Tile.getModelForType(type);
        this._isPlane = type.texture !== undefined;
        // this._mesh.castShadow = this._mesh.receiveShadow = true;
        this._mesh.position.set(this.x, 0, this.z);
        // if(y !== undefined)
        //    this._mesh.position.setY(y);
        // else
        this.setYPosition(heightMap);
        // this._mesh.position.y += Tile.getYOffset(type) * scale;
        this._mesh.scale.setScalar(scale);
        if (rotation !== undefined)
            this._mesh.rotation.copy(rotation);
        else
            Tile.applyDefaultRotationToModel(this._mesh, type);
        scene.add(this._mesh);
        this.collider.setFromObject(this._mesh);
        groundCtx.globalAlpha = this._isPlane ? .17 : .6;
        const shadowX = (_a = type.shadowX) !== null && _a !== void 0 ? _a : type.w;
        const shadowY = (_b = type.shadowY) !== null && _b !== void 0 ? _b : type.h;
        groundCtx.drawImage(ImgLoader_1.ImgLoader.getImg("shadow.png"), x * data_1.Global.fieldSize - data_1.Global.fieldSize * .4 * scale * shadowX, z * data_1.Global.fieldSize - data_1.Global.fieldSize * .4 * scale * shadowY, data_1.Global.fieldSize * .8 * scale * shadowX, data_1.Global.fieldSize * .8 * scale * shadowY);
        groundCtx.globalAlpha = 1;
    }
    applyRandomTransforms() {
        if (this.type.randomRot !== undefined) {
            this._mesh.rotation.x += (0, functions_2.rand)(-this.type.randomRot.x, this.type.randomRot.x);
            this._mesh.rotation.y += (0, functions_2.rand)(-this.type.randomRot.y, this.type.randomRot.y);
            this._mesh.rotation.z += (0, functions_2.rand)(-this.type.randomRot.z, this.type.randomRot.z);
        }
        if (this.type.randomScale !== undefined)
            this._mesh.scale.multiplyScalar((0, functions_2.rand)(1 / this.type.randomScale, this.type.randomScale));
    }
    static getModelForType(type) {
        if (type.model !== undefined) {
            if (type.dontPeel)
                return ModelLoader_1.ModelLoader.getModelCopyByPath("models/" + type.model, type.isOccluding);
            else
                return ModelLoader_1.ModelLoader.getRootModelCopyByPath("models/" + type.model, type.isOccluding);
        }
        else if (type.texture !== undefined)
            return RessourcePool_1.RessourcePool.getPlane(type.texture);
        throw new Error("No model or texture defined for tile type " + type.id);
    }
    setYPosition(heightMap) {
        this._mesh.position.setY((0, functions_1.getGroundHeightAtPosition)(this.x, this.z, heightMap));
        this._mesh.position.y += Tile.getYOffset(this.type) * this._mesh.scale.x;
    }
    static getYOffset(type) {
        let yOffset = 0;
        if (type.texture !== undefined)
            yOffset = .5;
        if (type.yOffset !== undefined)
            yOffset += type.yOffset;
        return yOffset;
    }
    static applyDefaultRotationToModel(model, type) {
        var _a;
        const rotation = _quat_0.setFromEuler((_a = type.defaultRot) !== null && _a !== void 0 ? _a : _eul_0.set(-Math.PI * .5, 0, 0));
        /*
        if(!type.noRandomYRotation)
            rotation.multiply(_quat_1.setFromAxisAngle(_vec3_0.set(0, 0, 1), Math.random() * Math.PI * 2));

        if(!type.noRandomYRotation)
        {
            rotation.multiply(_quat_1.setFromEuler(_eul_0.set(
                (Math.random() - .5) * .3,
                (Math.random() - .5) * .3,
                (Math.random() - .5) * .3
            )));
        }
        */
        model.rotation.setFromQuaternion(rotation);
    }
    getModelPosition() {
        return this._mesh.position;
    }
    onUpdate(desiredOpacity) {
        if (this._currentOpacity === desiredOpacity)
            return;
        this._currentOpacity = (0, functions_2.push)(this._currentOpacity, desiredOpacity, 0.1);
        this._mesh.material.opacity = this._currentOpacity;
        if (!this._isPlane)
            this._mesh.material.transparent = this._currentOpacity < 1;
    }
    onDestroy(scene) {
        scene.remove(this._mesh);
    }
    getMapTile() {
        return {
            x: this._mesh.position.x,
            y: this._mesh.position.y - Tile.getYOffset(this.type) * this._mesh.scale.x,
            z: this._mesh.position.z,
            rot: {
                x: this._mesh.rotation.x,
                y: this._mesh.rotation.y,
                z: this._mesh.rotation.z
            },
            scale: this._mesh.scale.x,
            type: this.type.id
        };
    }
}
exports.Tile = Tile;
/*

for(let i = 0; i < tiles.length; i++)
{
    const tile = tiles[i];

    const pos = _vec3_0.set(tile.x, getGroundHeightAtPosition(tile.x, tile.y, this._map.heightMap, DISPLACEMENT_SCALE), tile.y);
    const scale = _vec3_1.setScalar(rand(1.2, 1.5));

    const rotation = _quat_0.setFromAxisAngle(_vec3_2.set(1, 0, 0), -Math.PI * .5);
    const yAxisRotation = _quat_1.setFromAxisAngle(_vec3_2.set(0, 0, 1), Math.random() * Math.PI * 2);

    const smallRandomRotation = _quat_2.setFromEuler(
        _eul_0.set(
            (Math.random() - 0.5) * 0.3,
            (Math.random() - 0.5) * 0.3,
            (Math.random() - 0.5) * 0.3
        )
    );

    const finalRotation = rotation.multiply(yAxisRotation).multiply(smallRandomRotation);

    instancedMesh.setMatrixAt(i, new Matrix4().compose(pos, finalRotation, scale));
}

this._scene.add(instancedMesh);

*/ 
