"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sheep = void 0;
const three_1 = require("three");
const functions_1 = require("./functions");
const data_1 = require("../shared/data");
const functions_2 = require("../shared/functions");
const RessourcePool_1 = require("./RessourcePool");
const AudioPlayer_1 = require("./AudioPlayer");
const _vec2_0 = new three_1.Vector2();
const _vec2_1 = new three_1.Vector2();
const _vec3_0 = new three_1.Vector3();
const _vec3_1 = new three_1.Vector3();
const _white = new three_1.Color(1, 1, 1);
class Sheep {
    constructor(state, scene, grid, playerSheepPos) {
        this._pos = new three_1.Vector2();
        this._pos0 = new three_1.Vector2();
        this._drawPos = new three_1.Vector2();
        this._modelPosWithoutBounce = new three_1.Vector3();
        this._tickOfLastBlinkStart = -9999;
        this._drawWhiteUntil = -9999;
        this._collider = new three_1.Sphere(_vec3_0, .6);
        this._opac = 1;
        this._hasSpeed = false;
        this._passedDistance = 0;
        this._isMoving = false;
        this._isMovingLeft = false;
        this._id = state.id;
        this._pos.set(state.p.x, state.p.y);
        this._pos0.set(state.p.x, state.p.y);
        this._refreshOpacity(grid, playerSheepPos, true);
        this._plane = RessourcePool_1.RessourcePool.getPlane("sheep.png");
        this._plane.material.color.set(1, 1, 1);
        this._plane.scale.x = Math.random() < .5 ? 1 : -1;
        scene.add(this._plane);
    }
    get id() {
        return this._id;
    }
    get pos() {
        return this._pos;
    }
    blink(tick) {
        this._tickOfLastBlinkStart = tick;
    }
    updateAndGetCollisionSphere() {
        if (this._opac <= 0)
            return null;
        this._collider.center.copy(this._plane.position);
        this._collider.center.y -= .11;
        return this._collider;
    }
    onUpdate(tick, grid, state, heightMap, spriteEffectsRenderer, playerSheepPos) {
        if (state.blinking) {
            const from = _vec3_0.set(this._pos.x, 0, this._pos.y);
            const to = _vec3_1.set(state.p.x, 0, state.p.y);
            from.y = (0, functions_1.getGroundHeightAtPosition)(from.x, from.z, heightMap);
            to.y = (0, functions_1.getGroundHeightAtPosition)(to.x, to.z, heightMap);
            spriteEffectsRenderer.massSmoke3d(from, 1.25, tick, 30, "smoke2.png", _white, .2, .9, .4, 1.5, 1);
            spriteEffectsRenderer.massSmoke3d(to, 1.25, tick, 30, "smoke2.png", _white, .2, .9, .4, 1.5, 1);
            spriteEffectsRenderer.massSmoke3d(from, 1.25, tick, 30, "light.png", _white, .2, .9, .8, 2.5, .4);
            spriteEffectsRenderer.massSmoke3d(to, 1.25, tick, 30, "light.png", _white, .2, .9, .8, 3.5, .4);
            AudioPlayer_1.AudioPlayer.playSound("blink", from, .5);
            AudioPlayer_1.AudioPlayer.playSound("blink", to, .5);
        }
        const passedDistance = this._pos.distanceTo(state.p);
        this._passedDistance += passedDistance;
        this._isMoving = passedDistance > .001;
        if (passedDistance > .001) {
            const stepSoundEveryXTicks = Math.floor(2 / passedDistance);
            if (tick % stepSoundEveryXTicks === 0)
                AudioPlayer_1.AudioPlayer.playSound("step", this._plane.position, .115);
        }
        this._pos0.copy(this._pos);
        this._pos.set(state.p.x, state.p.y);
        if (state.p.x !== this._pos0.x)
            this._isMovingLeft = state.p.x < this._pos0.x;
        this._refreshOpacity(grid, playerSheepPos);
        if (state.hasSpeed) {
            if (!this._hasSpeed)
                AudioPlayer_1.AudioPlayer.playSound("charge", this._plane.position);
            if (this._opac > 0) {
                this._hasSpeed = true;
                const pos = _vec3_0.set(this._drawPos.x, .5 + (0, functions_1.getGroundHeightAtPosition)(this._drawPos.x, this._drawPos.y, heightMap), this._drawPos.y);
                const age = (0, functions_2.rand)(.5, 1);
                const v = _vec3_1.set(this.pos.x - this._pos0.x, 0, this.pos.y - this._pos0.y).multiplyScalar(-100).clampLength(0, 1).setY(1).multiplyScalar((0, functions_2.rand)(.6, 1.5));
                spriteEffectsRenderer.createSprite({
                    pos,
                    sprite: RessourcePool_1.RessourcePool.getSprite("smoke2.png", 1, 1, 1),
                    scale: (0, functions_2.rand)(.5, 1.5),
                    scaleFunc: age => Math.max(-Math.pow(2 * age - 1, 4) + 1, 0),
                    velocity: v,
                    moveFunc: age => age * 1.0,
                    alphaFunc: (age, s) => .66 * (s.r0 - age) / s.r0,
                    r0: age,
                    timeToLive: age,
                    rotation: Math.random() * Math.PI * 2
                }, tick);
            }
        }
        if (this._drawWhiteUntil === tick)
            this._plane.material.color.set(1, 1, 1);
    }
    get drawPos() {
        return this._drawPos;
    }
    getModelPos() {
        return this._modelPosWithoutBounce;
    }
    hoverCircleIsActive(tick, isHover) {
        if (this._tickOfLastBlinkStart + 36 > tick)
            return (tick - this._tickOfLastBlinkStart) % 4 < 2;
        return isHover;
    }
    getOpacity() {
        return this._opac;
    }
    _refreshOpacity(grid, playerSheepPos, instant) {
        if (playerSheepPos === undefined) {
            this._opac = 1;
            return;
        }
        const dist = this._drawPos.distanceTo(playerSheepPos);
        let desiredOpac = Math.min(Math.max(data_1.Global.sheepVisionRange - dist + .5, 0), 1);
        if (desiredOpac > 0) {
            const canSee = (0, functions_2.raycast)(playerSheepPos, this._pos, grid, _vec2_1).equals(this._pos);
            if (!canSee)
                desiredOpac = 0;
        }
        this._opac = (0, functions_2.push)(this._opac, desiredOpac, instant ? 1 : .11);
    }
    onFrame(lerpTime, heightMap) {
        this._drawPos.copy(this._pos0).lerp(this._pos, lerpTime);
        this._plane.material.opacity = this._opac;
        this._plane.position.set(this._drawPos.x, .5 + (0, functions_1.getGroundHeightAtPosition)(this._drawPos.x, this._drawPos.y, heightMap), this._drawPos.y);
        this._modelPosWithoutBounce.copy(this._plane.position);
        const scale = this._isMoving ? (Math.sin(this._passedDistance * 12) * .05 + 1) : 1;
        this._plane.scale.y = scale;
        this._plane.scale.x = 1 / scale * (this._isMovingLeft ? 1 : -1);
        this._plane.position.y -= (1 - scale) * 1.33;
    }
    onHit(tick) {
        this._drawWhiteUntil = tick + 2;
        this._plane.material.color.set(4, 4, 4);
        AudioPlayer_1.AudioPlayer.playSound("hit", this._plane.position, .7);
    }
    onRemove(scene) {
        scene.remove(this._plane);
    }
    onDeath(spriteEffectsRenderer, tick, visible) {
        if (visible)
            spriteEffectsRenderer.makeUnitDeathEffect(this._plane.position, .5, tick, new three_1.Color(1, 1, 1));
        AudioPlayer_1.AudioPlayer.playSound("zombie_bite", this._plane.position, .7);
    }
}
exports.Sheep = Sheep;
