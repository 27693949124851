"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.soundLinks = void 0;
function loadSound(files) {
    const target = [];
    for (const file of files)
        target.push(new Audio(file));
    return target;
}
;
exports.soundLinks = {
    flesh: loadSound([
        "sounds/hit05.ogg",
        "sounds/hit08.ogg",
        "sounds/hit13.ogg",
        "sounds/hit14.ogg"
    ]),
    pain: loadSound([
        "sounds/pain1.ogg",
        "sounds/pain2.ogg",
        "sounds/pain5.ogg"
    ]),
    punch: loadSound([
        "sounds/punch1.ogg",
        "sounds/punch2.ogg"
    ]),
    throw: loadSound([
        "sounds/throw-1.ogg",
        "sounds/throw-2.ogg"
    ]),
    potion: loadSound([
        "sounds/potion.ogg"
    ]),
    progress: loadSound([
        "sounds/progress.ogg"
    ]),
    scan: loadSound([
        "sounds/scan.ogg"
    ]),
    place: loadSound([
        "sounds/clic02.ogg"
    ]),
    negative: loadSound([
        "sounds/negative_2.ogg"
    ]),
    positive: loadSound([
        "sounds/misc_menu.ogg"
    ]),
    click: loadSound([
        "sounds/click1.ogg"
    ]),
    zoom: loadSound([
        "sounds/zoom.ogg"
    ]),
    click2: loadSound([
        "sounds/click.ogg"
    ]),
    repair: loadSound([
        "sounds/hammer1.ogg",
        "sounds/hammer2.ogg",
        "sounds/hammer3.ogg"
    ]),
    ingameclick: loadSound([
        "sounds/mouseclick1.ogg"
    ]),
    buildingfinished: loadSound([
        "sounds/building_finished.ogg",
        "sounds/building_finished.ogg"
    ]),
    underattack: loadSound([
        "sounds/under-attack.ogg"
    ]),
    victory: loadSound([
        "sounds/yeah.ogg"
    ]),
    buildingpain: loadSound([
        "sounds/building-hit.ogg",
        "sounds/building-hit-3.ogg",
        "sounds/building-hit.ogg",
        "sounds/building-hit-3.ogg"
    ]),
    gamestart: loadSound([
        "sounds/gamestart.ogg"
    ]),
    heal: loadSound([
        "sounds/heal.ogg",
        "sounds/heal.ogg"
    ]),
    blink: loadSound([
        "sounds/blink.ogg",
        "sounds/blink.ogg"
    ]),
    bing: loadSound([
        "sounds/bing2.ogg"
    ]),
    spell: loadSound([
        "sounds/spell.ogg",
        "sounds/spell.ogg"
    ]),
    flak: loadSound([
        "sounds/flak1.ogg",
        "sounds/flak2.ogg",
        "sounds/flak3.ogg"
    ]),
    auraheal: loadSound([
        "sounds/heal-aura.ogg",
        "sounds/heal-aura.ogg"
    ]),
    plasmashield: loadSound([
        "sounds/plasma-shield.ogg",
        "sounds/plasma-shield2.ogg",
        "sounds/plasma-shield3.ogg"
    ]),
    step: loadSound([
        "sounds/step1.ogg",
        "sounds/step2.ogg",
        "sounds/step3.ogg",
        "sounds/step4.ogg"
    ]),
    gunimpact: loadSound([
        "sounds/gun_impact_x.ogg",
        "sounds/gun_impact_x.ogg",
        "sounds/gun_impact_x.ogg"
    ]),
    gunwall: loadSound([
        "sounds/gun_hit_wall.ogg",
        "sounds/gun_hit_wall.ogg",
        "sounds/gun_hit_wall.ogg"
    ]),
    explo: loadSound([
        "sounds/explo1.ogg",
        "sounds/explo2.ogg"
    ]),
    cash: loadSound([
        "sounds/cash.ogg",
        "sounds/cash.ogg"
    ]),
    zip3: loadSound([
        "sounds/zip3.ogg",
        "sounds/zip3.ogg"
    ]),
    zombie_bite: loadSound([
        "sounds/zombie_bite.ogg",
        "sounds/zombie_bite_2.ogg",
        "sounds/zombie_bite_3.ogg"
    ]),
    kill: loadSound([
        "sounds/killS.ogg",
        "sounds/killS.ogg",
        "sounds/killS.ogg"
    ]),
    charge: loadSound([
        "sounds/charge.ogg"
    ]),
    death: loadSound([
        "sounds/death_sound.ogg"
    ]),
    background: loadSound([
        "sounds/background-noise.ogg",
        "sounds/background-noise.ogg"
    ]),
    spawn: loadSound([
        "sounds/spawn.ogg"
    ]),
    bird1: loadSound([
        "sounds/bird1.ogg",
        "sounds/bird1.ogg"
    ]),
    kick: loadSound([
        "sounds/kick1.ogg",
        "sounds/kick2.ogg",
        "sounds/kick3.ogg",
        "sounds/kick4.ogg"
    ]),
    cartridge1: loadSound([
        "sounds/cartridge1.ogg",
        "sounds/cartridge2.ogg",
        "sounds/cartridge4.ogg",
        "sounds/cartridge1.ogg",
        "sounds/cartridge2.ogg",
        "sounds/cartridge4.ogg",
        "sounds/cartridge1.ogg",
        "sounds/cartridge2.ogg"
    ]),
    cartridge2: loadSound([
        "sounds/cartridge3.ogg",
        "sounds/cartridge5.ogg",
        "sounds/cartridge3.ogg",
        "sounds/cartridge5.ogg",
        "sounds/cartridge3.ogg",
        "sounds/cartridge5.ogg",
        "sounds/cartridge3.ogg",
        "sounds/cartridge5.ogg"
    ]),
    grenadebounce: loadSound([
        "sounds/grenade-bounce.ogg",
        "sounds/grenade-bounce.ogg",
        "sounds/grenade-bounce.ogg"
    ]),
    hit: loadSound([
        "sounds/hit05.ogg",
        "sounds/hit08.ogg",
        "sounds/hit13.ogg",
        "sounds/hit14.ogg",
        "sounds/hit2.ogg"
    ])
};
