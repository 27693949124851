"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.push = exports.arrayErease = exports.getNextFreePositionFrom = exports.unitCouldStandAt = exports.raycast = exports.cos = exports.randOnDisc = exports.randomPointInSphere = exports.randomPointOnSphere = exports.seededRand = exports.PI = exports.addScaledVectorTo3d = exports.addScaledVectorTo2d = exports.rand = exports.findAndRemoveFromArray = void 0;
exports.sin = sin;
const three_1 = require("three");
const data_1 = require("./data");
const _vec2_0 = new three_1.Vector2();
const _vec2_1 = new three_1.Vector2();
const _vec3_0 = new three_1.Vector3();
const _angleOffsets = [[0.5, 0.86], [0.86, 0.5], [1, 0], [0.86, -0.5], [0.5, -0.86], [0, -1], [-0.5, -0.86], [-0.86, -0.5], [-1, 0], [-0.86, 0.5], [-0.5, 0.86], [0, 1]];
const _checkAngles = [[1, 0], [3, 1], [1, 1], [1, 3], [0, 1], [-1, 3], [-1, 1], [-3, 1], [-1, 0], [-3, -1], [-1, -1], [-1, -3], [0, -1], [1, -3], [1, -1], [3, -1]];
const _circleOffsets = [];
for (let k = 0; k < _checkAngles.length; k++) {
    const field = new three_1.Vector2(_checkAngles[k][0], _checkAngles[k][1]).setLength(.45);
    _circleOffsets.push([field.x, field.y]);
}
const findAndRemoveFromArray = (arr, func) => {
    for (let i = 0; i < arr.length; i++) {
        if (func(arr[i])) {
            arr.splice(i, 1);
            i--;
        }
    }
};
exports.findAndRemoveFromArray = findAndRemoveFromArray;
const rand = (min, max) => Math.random() * (max - min) + min;
exports.rand = rand;
const addScaledVectorTo2d = (from, to, length) => {
    return from.addScaledVector(_vec2_0.copy(to).sub(from).normalize(), length);
};
exports.addScaledVectorTo2d = addScaledVectorTo2d;
const addScaledVectorTo3d = (from, to, length) => {
    return from.addScaledVector(_vec3_0.copy(to).sub(from).normalize(), length);
};
exports.addScaledVectorTo3d = addScaledVectorTo3d;
exports.PI = 3.141592653589793;
const seededRand = (a) => {
    let t = a += 0x6D2B79F5;
    t = Math.imul(t ^ t >>> 15, t | 1);
    t ^= t + Math.imul(t ^ t >>> 7, t | 61);
    return ((t ^ t >>> 14) >>> 0) / 4294967296;
};
exports.seededRand = seededRand;
const randomPointOnSphere = (radius, out, seed) => {
    const theta = 2 * Math.PI * (seed ? (0, exports.seededRand)(seed) : Math.random()); // Random value from [0, 2pi]
    const phi = Math.acos(2 * (seed ? (0, exports.seededRand)((0, exports.seededRand)(seed) + 17) : Math.random()) - 1); // Random value from [0, pi]
    return out.set(radius * Math.sin(phi) * Math.cos(theta), radius * Math.sin(phi) * Math.sin(theta), radius * Math.cos(phi));
};
exports.randomPointOnSphere = randomPointOnSphere;
const randomPointInSphere = (radius, out, seed) => {
    return out.set((seed ? (0, exports.seededRand)(seed) : Math.random()) * 2 - 1, (seed ? (0, exports.seededRand)(seed + 17) : Math.random()) * 2 - 1, (seed ? (0, exports.seededRand)(seed + 31) : Math.random()) * 2 - 1).setLength(radius * Math.pow((seed ? (0, exports.seededRand)(seed + 47) : Math.random()), (1 / 3)));
};
exports.randomPointInSphere = randomPointInSphere;
const randOnDisc = (out) => {
    const r0 = Math.random();
    const r1 = Math.random();
    const r2 = Math.random();
    const t = 2 * Math.PI * r0;
    const u = r1 + r2;
    const r = u > 1 ? 2 - u : u;
    out.x = r * Math.cos(t);
    out.y = 0;
    out.z = r * Math.sin(t);
    return out;
};
exports.randOnDisc = randOnDisc;
function sin(x) {
    while (x > exports.PI)
        x -= exports.PI * 2;
    while (x < -exports.PI)
        x += exports.PI * 2;
    return x - Math.pow(x, 3) / 6 + Math.pow(x, 5) / 120 - Math.pow(x, 7) / 5040 + Math.pow(x, 9) / 362880;
}
;
const cos = (x) => {
    while (x > exports.PI)
        x -= exports.PI * 2;
    while (x < -exports.PI)
        x += exports.PI * 2;
    return 1 - x * x / 2 + Math.pow(x, 4) / 24 - Math.pow(x, 6) / 720 + Math.pow(x, 8) / 40320;
};
exports.cos = cos;
const raycast = (from, to, grid, copyToField) => {
    let x = from.x;
    let y = from.y;
    let xGrid = Math.floor(x);
    let yGrid = Math.floor(y);
    if (_getHeight(xGrid, yGrid, grid) > 1.5)
        return copyToField.set(x, y);
    const rateX = to.x - from.x;
    const rateY = to.y !== from.y ? to.y - from.y : 1e-6;
    const rate = rateX / rateY;
    const rateAbs = Math.abs(rate);
    const xGoesUp = rateX > 0;
    const yGoesUp = rateY > 0;
    while (true) {
        let xDist = xGoesUp ? (1 - x % 1) : (x % 1);
        if (xDist === 0)
            xDist = 1;
        let yDist = yGoesUp ? (1 - y % 1) : (y % 1);
        if (yDist === 0)
            yDist = 1;
        if (xDist / yDist > rateAbs) // y collision next
         {
            const yDelta = yGoesUp ? yDist : -yDist;
            y += yDelta;
            x += yDelta * rate;
            yGrid += yGoesUp ? 1 : -1;
        }
        else if (xDist / yDist < rateAbs) // x collision next
         {
            const xDelta = xGoesUp ? xDist : -xDist;
            x += xDelta;
            y += xDelta / rate;
            xGrid += xGoesUp ? 1 : -1;
        }
        else {
            const xDelta = xGoesUp ? xDist : -xDist;
            x += xDelta;
            y += xDelta / rate;
            xGrid += xGoesUp ? 1 : -1;
            if ((xGoesUp && x >= to.x) || (!xGoesUp && x <= to.x)) // past goal
                return copyToField.set(to.x, to.y);
            if (_getHeight(xGrid, yGrid, grid) > 1.5) {
                return copyToField.set(x, y);
            }
            yGrid += yGoesUp ? 1 : -1;
        }
        if ((xGoesUp && x >= to.x) || (!xGoesUp && x <= to.x)) // past goal
            return copyToField.set(to.x, to.y);
        if (_getHeight(xGrid, yGrid, grid) > 1.5) {
            return copyToField.set(x, y);
        }
    }
};
exports.raycast = raycast;
const _getHeight = (x, y, grid) => {
    return (x in grid && y in grid[x]) ? grid[x][y] : 9999;
};
const unitCouldStandAt = (pos, grid) => {
    for (const offset of _circleOffsets)
        if (_getHeight(Math.floor(pos.x + offset[0]), Math.floor(pos.y + offset[1]), grid) > 0)
            return false;
    return true;
};
exports.unitCouldStandAt = unitCouldStandAt;
const getNextFreePositionFrom = (pos, grid, out) => {
    if ((0, exports.unitCouldStandAt)(pos, grid))
        return pos;
    const xGrid = Math.floor(pos.x);
    const yGrid = Math.floor(pos.y);
    if (_getHeight(xGrid, yGrid, grid) === 0) {
        let x = pos.x;
        let y = pos.y;
        const xLow = x % 1;
        const yLow = y % 1;
        const xHigh = 1 - xLow;
        const yHigh = 1 - yLow;
        if (xLow - data_1.Global.sheepRadius < 0 && _getHeight(xGrid - 1, yGrid, grid) > 0)
            x = xGrid + data_1.Global.sheepRadius;
        else if (xLow + data_1.Global.sheepRadius > 1 && _getHeight(xGrid + 1, yGrid, grid) > 0)
            x = xGrid + 1 - data_1.Global.sheepRadius;
        if (yLow - data_1.Global.sheepRadius < 0 && _getHeight(xGrid, yGrid - 1, grid) > 0)
            y = yGrid + data_1.Global.sheepRadius;
        else if (yLow + data_1.Global.sheepRadius > 1 && _getHeight(xGrid, yGrid + 1, grid) > 0)
            y = yGrid + 1 - data_1.Global.sheepRadius;
        if (Math.pow((Math.pow(xHigh, 2) + Math.pow(yHigh, 2)), .5) < data_1.Global.sheepRadius && _getHeight(xGrid + 1, yGrid + 1, grid) > 0) {
            const m = data_1.Global.sheepRadius / (Math.pow((Math.pow(xHigh, 2) + Math.pow(yHigh, 2)), .5));
            return out.set((xGrid + 1) - xHigh * m, (yGrid + 1) - yHigh * m);
        }
        if (Math.pow((Math.pow(xHigh, 2) + Math.pow(yLow, 2)), .5) < data_1.Global.sheepRadius && _getHeight(xGrid + 1, yGrid - 1, grid) > 0) {
            const m = data_1.Global.sheepRadius / (Math.pow((Math.pow(xHigh, 2) + Math.pow(yLow, 2)), .5));
            return out.set((xGrid + 1) - xHigh * m, (yGrid) + yLow * m);
        }
        if (Math.pow((Math.pow(xLow, 2) + Math.pow(yHigh, 2)), .5) < data_1.Global.sheepRadius && _getHeight(xGrid - 1, yGrid + 1, grid) > 0) {
            const m = data_1.Global.sheepRadius / (Math.pow((Math.pow(xLow, 2) + Math.pow(yHigh, 2)), .5));
            return out.set((xGrid) + xLow * m, (yGrid + 1) - yHigh * m);
        }
        if (Math.pow((Math.pow(xLow, 2) + Math.pow(yLow, 2)), .5) < data_1.Global.sheepRadius && _getHeight(xGrid - 1, yGrid - 1, grid) > 0) {
            const m = data_1.Global.sheepRadius / (Math.pow((Math.pow(xLow, 2) + Math.pow(yLow, 2)), .5));
            return out.set((xGrid) + xLow * m, (yGrid) + yLow * m);
        }
        return out.set(x, y);
    }
    let testPos = pos;
    let len = .02;
    let i = 0;
    while (!(0, exports.unitCouldStandAt)(testPos, grid)) {
        testPos = pos.add(_vec2_1.set(_angleOffsets[i][0], _angleOffsets[i][1]).setLength(len));
        len += .02;
        i = (i + 1) % _angleOffsets.length;
        if (len > 10)
            return null;
    }
    return testPos;
};
exports.getNextFreePositionFrom = getNextFreePositionFrom;
const arrayErease = (arr, el) => {
    const index = arr.indexOf(el);
    if (index !== -1)
        arr.splice(index, 1);
};
exports.arrayErease = arrayErease;
const push = (from, to, maxDelta) => {
    const delta = to - from;
    if (delta > maxDelta)
        return from + maxDelta;
    if (delta < -maxDelta)
        return from - maxDelta;
    return to;
};
exports.push = push;
