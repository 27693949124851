"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImgLoader = void 0;
const three_1 = require("three");
const _imgs = new Map();
exports.ImgLoader = {
    loadImgs(path, ...files) {
        return Promise.all(files.map(file => _loadImg(path, file)));
    },
    getImg(path) {
        const img = _imgs.get(path);
        if (img === undefined)
            throw new Error(`Image ${path} not found.`);
        return img;
    },
    setImg(path, img) {
        _imgs.set(path, img);
    },
    dyeImg(img, color) {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        ctx.globalCompositeOperation = 'source-in';
        ctx.fillStyle = color;
        ctx.fillRect(0, 0, img.width, img.height);
        const newImg = new Image();
        newImg.src = canvas.toDataURL();
        return newImg;
    },
    replaceColors(img, color) {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const imgData = ctx.getImageData(0, 0, img.width, img.height);
        const data = imgData.data;
        for (let i = 0; i < data.length; i += 4) {
            const r = data[i];
            const g = data[i + 1];
            const b = data[i + 2];
            const brightness = (r + g + b) / 3;
            data[i] = color.r * brightness;
            data[i + 1] = color.g * brightness;
            data[i + 2] = color.b * brightness;
        }
        ctx.putImageData(imgData, 0, 0);
        const newImg = new Image();
        newImg.src = canvas.toDataURL();
        return newImg;
    },
    getAVGColor(img) {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const imgData = ctx.getImageData(0, 0, img.width, img.height);
        const data = imgData.data;
        let r = 0;
        let g = 0;
        let b = 0;
        for (let i = 0; i < data.length; i += 4) {
            r += data[i];
            g += data[i + 1];
            b += data[i + 2];
        }
        const count = data.length / 4;
        return new three_1.Color(r / count / 255, g / count / 255, b / count / 255);
    }
};
const _loadImg = (path, file) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = path + file;
        _imgs.set(file, img);
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error('Image could not be loaded.' + img.src));
    });
};
