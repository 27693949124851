"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Interface = void 0;
const data_1 = require("../shared/data");
const AudioPlayer_1 = require("./AudioPlayer");
const network_1 = require("./network");
const Shop_1 = require("./Shop");
const _timeDisplay = document.getElementById("timeDisplay");
const _goldSpan = document.getElementById("goldSpan");
const _hpInner = document.getElementById("hpInner");
const _chatLog = document.getElementById("chatLog");
const _importantMessages = document.getElementById("importantMessages");
const _deathDiv = document.getElementById("deathDiv");
const _killedBy = document.getElementById("killedBy");
const _deathRespawn = document.getElementById("deathRespawn");
const _deathRespawnTime = document.getElementById("deathRespawnTime");
const _respawnButton = document.getElementById("respawnButton");
let _gold = 0;
let _setGoldUntil = 0;
let _setGoldFrom = 0;
let _hpPerc = 1;
let _respawnTimer = 0;
class Interface {
    constructor() {
        this._importantrMsgs = [];
        _goldSpan.innerText = _gold.toString();
        this.setHp(1);
        this.updateGold(data_1.Global.initGold);
    }
    get gold() {
        return _gold;
    }
    setHp(hpPerc, killedBy) {
        _hpPerc = Math.max(hpPerc, 0);
        _hpInner.style.width = (_hpPerc * 100) + "%";
        if (killedBy !== undefined) {
            _deathDiv.classList.add("active");
            _deathRespawn.style.display = "";
            _respawnButton.style.display = "none";
            _killedBy.innerText = killedBy;
            _respawnTimer = data_1.Global.respawnTime;
            AudioPlayer_1.AudioPlayer.playSound("death");
        }
    }
    onRespawn() {
        _deathDiv.classList.remove("active");
        this.setHp(1);
        AudioPlayer_1.AudioPlayer.playSound("spawn");
    }
    hasFullHp() {
        return _hpPerc >= 1;
    }
    addMsg(msg, senderName, color) {
        const div = document.createElement("div");
        div.className = "new";
        _chatLog.appendChild(div);
        if (senderName !== null) {
            const nameSpan = document.createElement("span");
            nameSpan.innerText = senderName;
            nameSpan.className = "chatFrom playerNameColor";
            div.appendChild(nameSpan);
        }
        const msgSpan = document.createElement("span");
        msgSpan.innerText = msg;
        div.appendChild(msgSpan);
        if (color !== undefined)
            msgSpan.style.color = color;
        setTimeout(() => {
            div.classList.add("fadingOut");
            setTimeout(() => {
                div.classList.remove("new");
                div.classList.remove("fadingOut");
            }, 1000);
        }, 10 * 1000);
    }
    addImportantMsg(msg, duration, color) {
        const div = document.createElement("div");
        div.innerText = msg;
        div.style.color = color !== null && color !== void 0 ? color : "white";
        _importantMessages.appendChild(div);
        this._importantrMsgs.push({ div, ticksLeft: duration });
    }
    onTick(tick) {
        _timeDisplay.innerText = _getTimeStrFromTicks(tick);
        for (let i = this._importantrMsgs.length - 1; i >= 0; i--) {
            const msg = this._importantrMsgs[i];
            msg.ticksLeft--;
            if (msg.ticksLeft <= 0) {
                _importantMessages.removeChild(msg.div);
                this._importantrMsgs.splice(i, 1);
            }
        }
        if (_respawnTimer > 0) {
            _respawnTimer--;
            _deathRespawnTime.innerText = Math.ceil(_respawnTimer / data_1.Global.ticksPerSec).toString();
            if (_respawnTimer <= 0) {
                _deathRespawn.style.display = "none";
                _respawnButton.style.display = "";
            }
        }
    }
    updateGold(gold) {
        const diff = gold - _gold;
        if (diff > 0) {
            AudioPlayer_1.AudioPlayer.playSound("cash");
        }
        _setGoldUntil = Date.now() + 500;
        _setGoldFrom = _gold;
        _gold = gold;
        setTimeout(_setGoldInterval, 10);
        _goldSpan.innerText = gold.toString();
        Shop_1.ShopUI.refresh();
    }
    onFrame() {
    }
}
exports.Interface = Interface;
;
const _setGoldInterval = () => {
    const gold = Math.round(Math.min((Date.now() - (_setGoldUntil - 500)) / 500, 1) * (_gold - _setGoldFrom) + _setGoldFrom);
    _goldSpan.innerText = gold.toString();
    if (gold !== _gold)
        setTimeout(_setGoldInterval, 10);
};
const _getTimeStrFromTicks = (ticks) => {
    const seconds = Math.floor(ticks / data_1.Global.ticksPerSec);
    const minutes = Math.floor(seconds / 60);
    const timeStr = (minutes < 10 ? "0" : "") + minutes + ":" + (seconds % 60 < 10 ? "0" : "") + (seconds % 60);
    return timeStr;
};
_respawnButton.onclick = () => {
    const msg = {
        respawn: true
    };
    network_1.Network.send(msg);
};
