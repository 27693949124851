"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tileTypes = void 0;
const three_1 = require("three");
exports.tileTypes = [
    {
        id: "tree1",
        w: 1.1,
        h: 1.1,
        model: "tree1.glb",
        color: 0x1e281a,
        isOccluding: true
    },
    {
        id: "tree2",
        w: 1.1,
        h: 1.1,
        model: "tree2.glb",
        color: 0x1e281a,
        isOccluding: true
    },
    {
        id: "tree3",
        w: 1.5,
        h: 1.5,
        model: "tree3.glb",
        color: 0x1e281a,
        isOccluding: true
    },
    {
        id: "tree4",
        w: .9,
        h: .9,
        model: "tree4.glb",
        color: 0x1e281a,
        isOccluding: true
    },
    {
        id: "trunk",
        w: 2,
        h: 1,
        model: "trunk.glb",
        color: 0x82716c
    },
    {
        id: "wall",
        w: 3,
        h: 1,
        model: "wall.glb",
        color: 0xa1a1a1,
        isOccluding: true
    },
    {
        id: "stone",
        w: 4.3,
        h: 3.0,
        model: "stone.glb",
        color: 0xa1a1a1,
        isOccluding: true
    },
    {
        id: "stone2",
        w: .8,
        h: .8,
        model: "stone2.glb",
        color: 0xa1a1a1,
        isOccluding: true
    },
    {
        id: "path-stone",
        w: 1.1,
        h: 1.1,
        model: "path-stone.glb"
    },
    {
        id: "mushroom",
        w: .5,
        h: .5,
        model: "mushroom.glb"
    },
    {
        id: "mushroom2",
        w: .5,
        h: .5,
        model: "mushroom2.glb"
    },
    {
        id: "grass",
        w: 1.9,
        h: 1.9,
        model: "grass.glb"
    },
    {
        id: "grass-tex",
        w: 1,
        h: .2,
        defaultRot: new three_1.Euler(0, 0, 0),
        randomRot: new three_1.Euler(.25, .25, .25),
        noRandomYRotation: true,
        scale: 1.5,
        randomScale: 1.5,
        texture: "grass.png",
        yOffset: -.1,
        yAxis: "y"
    },
    {
        id: "grass-tuft",
        w: 1,
        h: .2,
        defaultRot: new three_1.Euler(0, 0, 0),
        randomRot: new three_1.Euler(.25, .25, .25),
        noRandomYRotation: true,
        scale: 1.5,
        randomScale: 1.5,
        texture: "grassTuft.png",
        yAxis: "y"
    },
    {
        id: "grasses",
        w: 1,
        h: .2,
        defaultRot: new three_1.Euler(0, 0, 0),
        randomRot: new three_1.Euler(.25, .25, .25),
        noRandomYRotation: true,
        scale: 1.5,
        randomScale: 1.5,
        texture: "grasses.png",
        yAxis: "y"
    },
    {
        id: "grass2",
        w: 1.9,
        h: 1.9,
        model: "grass2.glb",
        defaultRot: new three_1.Euler(0, 0, 0),
        noRandomYRotation: true,
        yAxis: "y"
    },
    {
        id: "grass3",
        w: 1,
        h: 1,
        model: "grass3.glb",
        defaultRot: new three_1.Euler(0, 0, 0),
        noRandomYRotation: true,
        yAxis: "y",
        yOffset: 5,
        scale: .15
    },
    {
        id: "flower",
        w: .5,
        h: .5,
        model: "flower.glb"
    },
    {
        id: "column",
        w: 1,
        h: 1,
        shadowX: 1.4,
        shadowY: 1.4,
        model: "column.glb",
        color: 0xa1a1a1,
        isOccluding: true
    },
    {
        id: "barrel",
        w: 1,
        h: 1,
        shadowX: 1.6,
        shadowY: 1.6,
        model: "barrel.glb",
        color: 0x82716c,
        isOccluding: true
    },
    {
        id: "shop",
        w: 3,
        h: 3,
        shadowX: 90,
        shadowY: 70,
        model: "shop.glb",
        scale: .06,
        noRandomYRotation: true,
        color: 0xffff00,
        isShop: true
    },
    {
        id: "fountain",
        w: 3,
        h: 3,
        shadowX: 5.5,
        shadowY: 5.5,
        model: "fountain.glb",
        scale: .9,
        noRandomYRotation: true,
        color: 0xffff00,
        isFountain: true,
        yOffset: .45,
        isOccluding: true
    }
];
