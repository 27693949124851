"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getKeyCode = exports.Input = void 0;
const three_1 = require("three");
const _1 = require(".");
const Keys_1 = require("./Keys");
const network_1 = require("./network");
const data_1 = require("../shared/data");
const _canvas = document.getElementById("canvas");
const _chatDiv = document.getElementById("chatDiv");
const _chatInput = document.getElementById("chatInput");
const _vec2_0 = new three_1.Vector2();
const _vec2_1 = new three_1.Vector2();
let _game = null;
let _editor = null;
exports.Input = {
    x: 0,
    y: 0,
    leftMouse: false,
    rightMouse: false,
    keys: new Array(300),
    setGame(game) {
        _game = game;
    },
    setEditor(editor) {
        _editor = editor;
    },
    getScreenPos(out) {
        return out.set((exports.Input.x / window.innerWidth) * 2 - 1, -(exports.Input.y / window.innerHeight) * 2 + 1);
    }
};
document.onkeyup = function (e) {
    exports.Input.keys[(0, exports.getKeyCode)(e)] = false;
    return false;
};
if (!data_1.DEBUG)
    document.addEventListener('contextmenu', (event) => event.preventDefault());
document.onkeydown = function (e) {
    const key = (0, exports.getKeyCode)(e);
    if (key === Keys_1.KEY.F8)
        (0, _1.toggleFullscreen)(document.documentElement);
    if (_editor !== null) {
        _editor.onKey(key);
    }
    if (key === Keys_1.KEY.ENTER && _game !== null) {
        if (!_chatDiv.classList.contains("active")) {
            _chatDiv.classList.add("active");
            _chatInput.focus();
        }
        else {
            _chatDiv.classList.remove("active");
            if (_chatInput.value.length > 0) {
                const msg = {
                    chat: _chatInput.value
                };
                _chatInput.value = "";
                network_1.Network.send(msg);
            }
        }
    }
    if (_chatDiv.classList.contains("active") && _chatInput === document.activeElement)
        return true;
    exports.Input.keys[key] = true;
    return false;
};
document.addEventListener("wheel", (e) => {
    if (_editor !== null)
        _editor.onMouseWheel(e);
});
_canvas.onmousedown = function (e) {
    const key = (0, exports.getKeyCode)(e);
    if (key === 1)
        exports.Input.leftMouse = true;
    else if (key === 3)
        exports.Input.rightMouse = true;
    if (_editor !== null) {
        _editor.onClick(exports.Input.getScreenPos(_vec2_0), key);
        return;
    }
    if (_game === null)
        return;
    const field = _game.getPosFromMouseCoords(exports.Input.getScreenPos(_vec2_0));
    if (key === 1) {
        if (field !== null)
            _game.inventory.onLeftClick(field);
    }
    else if (key === 3) {
        if (_game.inventory.onRightClick() && field !== null) {
            const targetUnit = _game.getUnitAtPosition(exports.Input.getScreenPos(_vec2_1));
            // if theres an enemy unit at this point, attack
            if (targetUnit !== null && targetUnit !== _game.getPlayerSheep()) {
                _game.orderAttack(targetUnit, exports.Input.keys[Keys_1.KEY.SHIFT]);
                targetUnit.blink(_game.ticksCounter);
            }
            // if theres no enemy unit => move / waypoint
            else {
                const pos = _game.orderMove(_vec2_0.set(field.x, field.z), exports.Input.keys[Keys_1.KEY.SHIFT]);
                if (pos !== null)
                    _game.createGroundOrder(pos);
            }
        }
    }
    return false;
};
// this prevents the context menu when shift right clicking (at least for some browsers)
document.onclick = function (e) {
    const b = (0, exports.getKeyCode)(e);
    if ((b === 2 || b === 3) && _game) {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }
};
document.onmouseup = function (e) {
    const key = (0, exports.getKeyCode)(e);
    if (key === 1)
        exports.Input.leftMouse = false;
    else if (key === 3)
        exports.Input.rightMouse = false;
    return false;
};
// document.addEventListener( 'mousemove', onCanvasMouseMove, { capture: true, passive: true });
// document.addEventListener( 'pointerrawupdate', onCanvasMouseMove, { capture: true, passive: true });
document.addEventListener("pointerrawupdate", function (e) {
    // Calculate pageX/Y if missing and clientX/Y available
    if (e.pageX == null && e.clientX != null) {
        const doc = document.documentElement;
        const body = document.body;
        e.pageX = e.clientX + (doc && doc.scrollLeft || body && body.scrollLeft || 0) - (doc && doc.clientLeft || body && body.clientLeft || 0);
        e.pageY = e.clientY + (doc && doc.scrollTop || body && body.scrollTop || 0) - (doc && doc.clientTop || body && body.clientTop || 0);
    }
    exports.Input.x = e.pageX;
    exports.Input.y = e.pageY;
    // fix shift bug that sometimes happens (special thanks to pox)
    if (!e)
        e = document.event;
    if (!e.shiftKey)
        exports.Input.keys[Keys_1.KEY.SHIFT] = false;
});
const getKeyCode = function (e) {
    return e.which || e.keyCode;
};
exports.getKeyCode = getKeyCode;
