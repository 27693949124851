"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Editor = void 0;
const three_1 = require("three");
const Game_1 = require("./Game");
const Input_1 = require("./Input");
const data_1 = require("../shared/data");
const Keys_1 = require("./Keys");
const functions_1 = require("../shared/functions");
const tiles_1 = require("../shared/tiles");
const functions_2 = require("./functions");
const Tile_1 = require("./Tile");
const _heightPlus = document.getElementById("heightPlus");
const _heightSmooth = document.getElementById("heightSmooth");
const _editorMods = document.getElementById("editorMods");
const _brushSizeSlider = document.getElementById("brushSize");
const _brushStrengthSlider = document.getElementById("brushStrength");
const _editorTilesDiv = document.getElementById("editorTilesDiv");
const _editorSelect = document.getElementById("editorSelect");
const _collisionButton = document.getElementById("collisionButton");
const _editorSave = document.getElementById("editorSave");
const _editorDivBottom = document.getElementById("editorDivBottom");
let _currentTileType = null;
let _selectedTile = null;
let _currentModel = null;
let _modifyHeight = false;
let _smoothHeight = false;
let _modifyCollision = false;
let _radius = 2;
let _brushStrength = 5;
let _brushSizeSliderIsHovered = false;
const _lastField = new three_1.Vector2(-9999, -9999);
const _vec2_0 = new three_1.Vector2();
const _vec2_1 = new three_1.Vector2();
const _vec2_2 = new three_1.Vector2();
class Editor {
    constructor(map) {
        this._map = map;
        const state = {
            map: map,
            myId: "",
            playerList: [],
            tick: 0
        };
        this._game = new Game_1.Game(state);
        for (const type of tiles_1.tileTypes) {
            const button = document.createElement("button");
            if (type.model !== undefined)
                button.style.backgroundImage = "url(" + _createImageFromModel(Tile_1.Tile.getModelForType(type), type).src + ")";
            else if (type.texture !== undefined)
                button.style.backgroundImage = "url(textures/" + type.texture + ")";
            button.onclick = () => {
                _reset();
                _currentTileType = type;
                _currentModel = Tile_1.Tile.getModelForType(type);
                this._game.addObject(_currentModel);
                Tile_1.Tile.applyDefaultRotationToModel(_currentModel, type);
                if (type.scale !== undefined)
                    _currentModel.scale.setScalar(type.scale);
                button.classList.add('active');
            };
            _editorTilesDiv.appendChild(button);
        }
        _editorSave.onclick = () => this.exportMap();
    }
    onClick(pos, key, lerpTime = (1 / 60), newClick = true) {
        const pos3d = this._game.getPosFromMouseCoords(pos);
        if (_modifyHeight && pos3d !== null) {
            const heightMod = (key === 1 ? _brushStrength : -_brushStrength) * lerpTime * 15;
            for (let x = pos3d.x - _radius - 1; x <= pos3d.x + _radius + 1; x++) {
                for (let z = pos3d.z - _radius - 1; z <= pos3d.z + _radius + 1; z++) {
                    if (Math.sqrt(Math.pow((x - pos3d.x), 2) + Math.pow((z - pos3d.z), 2)) <= _radius)
                        this._map.heightMap[Math.round(x + data_1.Global.mapSideDeadZone)][Math.round(z + data_1.Global.mapTopDeadZone)] += heightMod;
                }
            }
            this._game.applyHeightMap();
            this._game.refreshTileYPositions();
        }
        else if (_smoothHeight && pos3d !== null) {
            let avg = 0;
            let count = 0;
            for (let x = pos3d.x - _radius - 1; x <= pos3d.x + _radius + 1; x++) {
                for (let z = pos3d.z - _radius - 1; z <= pos3d.z + _radius + 1; z++) {
                    if (Math.sqrt(Math.pow((x - pos3d.x), 2) + Math.pow((z - pos3d.z), 2)) <= _radius) {
                        avg += this._map.heightMap[Math.round(x + data_1.Global.mapSideDeadZone)][Math.round(z + data_1.Global.mapTopDeadZone)];
                        count++;
                    }
                }
            }
            avg /= count;
            for (let x = pos3d.x - _radius - 1; x <= pos3d.x + _radius + 1; x++) {
                for (let z = pos3d.z - _radius - 1; z <= pos3d.z + _radius + 1; z++) {
                    if (Math.sqrt(Math.pow((x - pos3d.x), 2) + Math.pow((z - pos3d.z), 2)) <= _radius) {
                        let val = this._map.heightMap[Math.round(x + data_1.Global.mapSideDeadZone)][Math.round(z + data_1.Global.mapTopDeadZone)];
                        val = (0, functions_1.push)(val, avg, .01 * data_1.Global.heightmapPrecision * lerpTime * _brushStrength);
                        this._map.heightMap[Math.round(x + data_1.Global.mapSideDeadZone)][Math.round(z + data_1.Global.mapTopDeadZone)] = val;
                    }
                }
            }
            this._game.applyHeightMap();
            this._game.refreshTileYPositions();
        }
        if (pos3d !== null && (newClick || (_vec2_0.set(pos3d.x, pos3d.z).distanceTo(_lastField) > 1)) && _currentTileType !== null && _currentModel !== null) {
            const y = _currentModel.position.y - Tile_1.Tile.getYOffset(_currentTileType) * _currentModel.scale.x;
            const t = this._game.addTile(_currentTileType, pos3d.x, y, pos3d.z, _currentModel.scale.x, _currentModel.rotation);
            if (_currentTileType.randomRot !== undefined)
                t.applyRandomTransforms();
            _lastField.set(pos3d.x, pos3d.z);
        }
        if (_currentModel === null && !_smoothHeight && !_modifyHeight && !_modifyCollision) {
            _selectedTile = this._game.getTileAtPosition(pos);
        }
        if (_modifyCollision && pos3d !== null) {
            const grid = this._game.grid;
            const x = Math.floor(pos3d.x);
            const z = Math.floor(pos3d.z);
            if (x in grid && z in grid[x])
                grid[x][z] = key === 1 ? 4 : 0;
        }
    }
    resize() {
        this._game.resize();
    }
    onMouseWheel(event) {
        if (event.deltaY > 0)
            this._game.camera.position.y += .66;
        else
            this._game.camera.position.y -= .66;
    }
    onKey(key) {
        if (key === Keys_1.KEY.ESC || key === Keys_1.KEY.SPACE)
            _reset();
        if ((key === Keys_1.KEY.E || key === Keys_1.KEY.DELETE) && _selectedTile !== null) {
            this._game.removeTile(_selectedTile);
            _selectedTile = null;
        }
    }
    onRender(lerpTime) {
        var _a, _b, _c, _d;
        const pos3d = this._game.getPosFromMouseCoords(Input_1.Input.getScreenPos(_vec2_0));
        const ctx = this._game.visionCtx;
        if (_currentModel !== null && pos3d !== null && _currentTileType !== null) {
            _currentModel.position.set(pos3d.x, (0, functions_2.getGroundHeightAtPosition)(pos3d.x, pos3d.z, this._map.heightMap), pos3d.z);
            _currentModel.position.y += Tile_1.Tile.getYOffset(_currentTileType) * _currentModel.scale.x;
            if (Input_1.Input.keys[Keys_1.KEY.Q])
                _currentModel.rotation[(_a = _currentTileType.yAxis) !== null && _a !== void 0 ? _a : "z"] += lerpTime * 1.5;
            if (Input_1.Input.keys[Keys_1.KEY.E])
                _currentModel.rotation[(_b = _currentTileType.yAxis) !== null && _b !== void 0 ? _b : "z"] -= lerpTime * 1.5;
            if (Input_1.Input.keys[Keys_1.KEY.NUM1])
                _currentModel.scale.setScalar(_currentModel.scale.x + lerpTime * .8);
            if (Input_1.Input.keys[Keys_1.KEY.NUM2] || Input_1.Input.keys[Keys_1.KEY.NUM3])
                _currentModel.scale.setScalar(_currentModel.scale.x - lerpTime * .8);
        }
        this._game.visionCanvas.width = this._game.visionCanvas.width;
        if (_brushSizeSliderIsHovered) {
            const screenCenter3d = this._game.getPosFromMouseCoords(_vec2_1.set(0, 0));
            if (screenCenter3d !== null) {
                _drawCircle(ctx, screenCenter3d.x * data_1.Global.visionFieldSize, screenCenter3d.z * data_1.Global.visionFieldSize, _radius * data_1.Global.visionFieldSize, "white", "rgba(255, 255, 255, .1)");
                this._game.visionTexture.needsUpdate = true;
            }
        }
        else if ((_modifyHeight || _smoothHeight) && _radius > 0 && pos3d !== null) {
            _drawCircle(ctx, pos3d.x * data_1.Global.visionFieldSize, pos3d.z * data_1.Global.visionFieldSize, _radius * data_1.Global.visionFieldSize, "white", "rgba(255, 255, 255, .1)");
            this._game.visionTexture.needsUpdate = true;
        }
        if (Input_1.Input.keys[Keys_1.KEY.LEFT] || Input_1.Input.keys[Keys_1.KEY.A])
            this._game.camera.position.x -= .26;
        if (Input_1.Input.keys[Keys_1.KEY.RIGHT] || Input_1.Input.keys[Keys_1.KEY.D])
            this._game.camera.position.x += .26;
        if (Input_1.Input.keys[Keys_1.KEY.UP] || Input_1.Input.keys[Keys_1.KEY.W])
            this._game.camera.position.z -= .26;
        if (Input_1.Input.keys[Keys_1.KEY.DOWN] || Input_1.Input.keys[Keys_1.KEY.S])
            this._game.camera.position.z += .26;
        if ((Input_1.Input.leftMouse || Input_1.Input.rightMouse) && pos3d !== null)
            this.onClick(Input_1.Input.getScreenPos(_vec2_2), Input_1.Input.leftMouse ? 1 : 3, lerpTime, false);
        if (_selectedTile !== null) {
            _drawCircle(ctx, _selectedTile.x * data_1.Global.visionFieldSize, _selectedTile.z * data_1.Global.visionFieldSize, _selectedTile.type.w * data_1.Global.visionFieldSize, "yellow", "rgba(255, 255, 0, .5)", [5, 5], Date.now() / 1000);
            this._game.visionTexture.needsUpdate = true;
        }
        if (_currentModel === null && !_smoothHeight && !_modifyHeight && !_modifyCollision) {
            const hoverTile = this._game.getTileAtPosition(Input_1.Input.getScreenPos(_vec2_1));
            if (hoverTile !== null) {
                _drawCircle(ctx, hoverTile.x * data_1.Global.visionFieldSize, hoverTile.z * data_1.Global.visionFieldSize, hoverTile.type.w * data_1.Global.visionFieldSize, "rgba(255, 255, 0, .5)", "rgba(255, 255, 0, .25)", [5, 5], Date.now() / 1000);
                this._game.visionTexture.needsUpdate = true;
            }
        }
        /* if(_modifyCollision && pos3d !== null) */
        {
            ctx.globalAlpha = .75;
            ctx.strokeStyle = "white";
            ctx.lineWidth = 1;
            ctx.setLineDash([]);
            ctx.beginPath();
            for (let x = 0; x < this._map.x; x++) {
                ctx.moveTo(x * data_1.Global.visionFieldSize, 0);
                ctx.lineTo(x * data_1.Global.visionFieldSize, this._map.y * data_1.Global.visionFieldSize);
            }
            for (let y = 0; y < this._map.y; y++) {
                ctx.moveTo(0, y * data_1.Global.visionFieldSize);
                ctx.lineTo(this._map.x * data_1.Global.visionFieldSize, y * data_1.Global.visionFieldSize);
            }
            ctx.closePath();
            ctx.stroke();
            if (_modifyCollision && pos3d !== null) {
                ctx.fillStyle = "rgba(255, 255, 255, .4)";
                ctx.fillRect(Math.floor(pos3d.x) * data_1.Global.visionFieldSize, Math.floor(pos3d.z) * data_1.Global.visionFieldSize, data_1.Global.visionFieldSize, data_1.Global.visionFieldSize);
            }
            const grid = this._game.grid;
            for (let x = 0; x < this._map.x; x++) {
                for (let y = 0; y < this._map.y; y++) {
                    if (!(x in grid) || !(y in grid[x]) || grid[x][y] > 0) {
                        ctx.fillStyle = "rgba(255, 0, 0, .5)";
                        ctx.fillRect(x * data_1.Global.visionFieldSize, y * data_1.Global.visionFieldSize, data_1.Global.visionFieldSize, data_1.Global.visionFieldSize);
                    }
                }
            }
            this._game.visionTexture.needsUpdate = true;
        }
        this._game.onRender(lerpTime);
        _editorDivBottom.innerText = `x: ${Math.round(((_c = pos3d === null || pos3d === void 0 ? void 0 : pos3d.x) !== null && _c !== void 0 ? _c : 0) * 100) / 100}, z: ${Math.round(((_d = pos3d === null || pos3d === void 0 ? void 0 : pos3d.z) !== null && _d !== void 0 ? _d : 0) * 100) / 100}`;
    }
    exportMap() {
        console.log(this._game.tiles.length);
        const map = {
            x: this._map.x,
            y: this._map.y,
            heightMap: this._map.heightMap,
            flowers: this._map.flowers,
            groundTexture: this._map.groundTexture,
            tiles: this._game.tiles.map(tile => tile.getMapTile()),
            collision: this._game.grid
        };
        // download
        const a = document.createElement('a');
        const file = new Blob([JSON.stringify(map)], { type: 'text/plain' });
        a.href = URL.createObjectURL(file);
        a.download = 'map.json';
        a.click();
    }
}
exports.Editor = Editor;
_brushStrengthSlider.oninput = () => _brushStrength = parseFloat(_brushStrengthSlider.value);
_brushSizeSlider.oninput = () => _radius = parseFloat(_brushSizeSlider.value);
_brushSizeSlider.onmouseover = () => _brushSizeSliderIsHovered = true;
_brushSizeSlider.onmouseout = () => _brushSizeSliderIsHovered = false;
const _drawCircle = (ctx, x, y, radius, color, fillColor, dash, offset) => {
    ctx.setLineDash(dash !== null && dash !== void 0 ? dash : []);
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.arc(x, y, radius, (offset !== null && offset !== void 0 ? offset : 0) % Math.PI * 2, (offset !== null && offset !== void 0 ? offset : 0) % Math.PI * 2 + Math.PI * 2);
    ctx.closePath();
    ctx.strokeStyle = color;
    ctx.stroke();
    if (fillColor !== undefined) {
        ctx.fillStyle = fillColor;
        ctx.fill();
    }
};
const _reset = () => {
    _editorTilesDiv.childNodes.forEach((node) => node.classList.remove('active'));
    if (_currentModel !== null && _currentModel.parent !== null) {
        _currentModel.parent.remove(_currentModel);
        _currentModel = null;
    }
    _currentTileType = null;
    _modifyHeight = false;
    _smoothHeight = false;
    _modifyCollision = false;
    _editorMods.style.display = "none";
    _heightPlus.classList.remove('active');
    _heightSmooth.classList.remove('active');
    _collisionButton.classList.remove('active');
};
_heightPlus.onclick = () => {
    _reset();
    _modifyHeight = true;
    _editorMods.style.display = "";
    _heightPlus.classList.add('active');
};
_heightSmooth.onclick = () => {
    _reset();
    _smoothHeight = true;
    _editorMods.style.display = "";
    _heightSmooth.classList.add('active');
};
_collisionButton.onclick = () => {
    _reset();
    _modifyCollision = true;
    _collisionButton.classList.add('active');
};
_editorSelect.onclick = () => _reset();
const _scene = new three_1.Scene();
const _cam = new three_1.PerspectiveCamera(75, 1, .1, 1000);
const _renderer = new three_1.WebGLRenderer({
    antialias: true,
    alpha: true
});
_renderer.setSize(64, 64);
_renderer.setClearColor(0xadffb8, .5);
_cam.position.set(.3, .9, .9);
_cam.lookAt(0, .5, 0);
_scene.add(_cam);
const _light = new three_1.AmbientLight(0xffffff, 2);
_scene.add(_light);
const _box = new three_1.Box3();
const _vec3 = new three_1.Vector3();
const _createImageFromModel = (model, type) => {
    _scene.add(model);
    _box.setFromObject(model);
    const size = _box.getSize(_vec3);
    const max = Math.max(size.x, size.y, size.z);
    model.scale.set(1 / max, 1 / max, 1 / max);
    Tile_1.Tile.applyDefaultRotationToModel(model, type);
    _renderer.render(_scene, _cam);
    _scene.remove(model);
    const img = new Image();
    img.src = _renderer.domElement.toDataURL();
    return img;
};
