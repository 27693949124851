"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Network = void 0;
const _1 = require(".");
const SERVER_ADRESS = "ws://localhost:62203";
exports.Network = {
    connected: false,
    timeOfLastPingSent: Date.now(),
    pings: [],
    socket: null,
    connect() {
        try {
            exports.Network.socket = new WebSocket(SERVER_ADRESS);
        }
        catch (e) {
            alert("You browser seems to not support Websockets. Websockets, however, are required to run this game.");
            throw new Error("No Websockets supported.");
        }
        exports.Network.socket.onopen = (ev) => {
            exports.Network.connected = true;
            console.log("Connected to server.");
            // autologin
            /*
            const pw = getPWFromCookie();
            if(pw && pw.length > 0)
            {
                UI.autologin(pw);
            }
            */
            // Network.send({ join: true });
        };
        exports.Network.socket.onclose = (ev) => {
            exports.Network.connected = false;
        };
        exports.Network.socket.onmessage = (data) => {
            (0, _1.onIncomingMessage)(JSON.parse(data.data));
        };
    },
    send(data) {
        if (exports.Network.connected && exports.Network.socket !== null) {
            exports.Network.socket.send(JSON.stringify(data));
            return;
        }
        console.log("No connection.");
    }
};
