"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RankList = void 0;
const AudioPlayer_1 = require("./AudioPlayer");
const _rankListDiv = document.getElementById("rankListDiv");
const _killsDiv = document.getElementById("killsDiv");
const _players = {};
exports.RankList = {
    init(playerList) {
        for (const p of playerList)
            exports.RankList.add(p.id, p.name, p.score, true);
        exports.RankList.sort();
    },
    add(id, name, score, noSort) {
        const div = document.createElement("div");
        div.innerHTML = `<span class="rankEntryId">${id}</span><span class="rankEntryName">${name}</span><span class="rankEntryScore">${score}</span>`;
        _players[id] = { name, score, div };
        if (!noSort)
            exports.RankList.sort();
    },
    addKillMsg(killerId, victimId, score) {
        const killer = _players[killerId];
        const victim = _players[victimId];
        if (killer === undefined || victim === undefined)
            return;
        const colDiv = document.createElement("div");
        const killerSpan = document.createElement("span");
        killerSpan.textContent = killer.name;
        killerSpan.className = "playerNameColor";
        colDiv.appendChild(killerSpan);
        const killedSpan = document.createElement("span");
        killedSpan.textContent = "killed";
        killedSpan.className = "killed";
        colDiv.appendChild(killedSpan);
        const victimSpan = document.createElement("span");
        victimSpan.textContent = victim.name;
        victimSpan.className = "playerNameColor";
        colDiv.appendChild(victimSpan);
        _killsDiv.insertBefore(colDiv, _killsDiv.firstChild);
        killer.score = score;
        killer.div.querySelector(".rankEntryScore").textContent = score.toString();
        exports.RankList.sort();
        AudioPlayer_1.AudioPlayer.playSound("kill", undefined, .7);
        setTimeout(() => {
            colDiv.style.transition = "opacity 1s";
            colDiv.style.opacity = "0";
            setTimeout(() => {
                colDiv.remove();
            }, 2000);
        }, 5000);
    },
    remove(id) {
        const player = _players[id];
        player.div.remove();
        delete _players[id];
    },
    sort() {
        const sortedPlayers = Object.values(_players).sort((a, b) => b.score - a.score).slice(0, 10);
        _rankListDiv.innerHTML = "";
        for (const player of sortedPlayers)
            _rankListDiv.appendChild(player.div);
    }
};
