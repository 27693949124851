"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Projectile = void 0;
const three_1 = require("three");
const functions_1 = require("../shared/functions");
const RessourcePool_1 = require("./RessourcePool");
const functions_2 = require("./functions");
const AudioPlayer_1 = require("./AudioPlayer");
const _vec3_0 = new three_1.Vector3();
const _vec3_1 = new three_1.Vector3();
const _vec3_2 = new three_1.Vector3();
class Projectile {
    constructor(state, scene, visible) {
        this._pos = new three_1.Vector3();
        this._pos0 = new three_1.Vector3();
        this._rotationSpeed = (0, functions_1.rand)(-1, 1);
        this._colorOffset = (0, functions_1.rand)(0, 99999);
        this._isDead = false;
        this._id = state.id;
        this._pos.set(state.pos.x, state.pos.y, state.pos.z);
        this._pos0.set(state.pos.x, state.pos.y, state.pos.z);
        AudioPlayer_1.AudioPlayer.playSound("throw", this._pos, .4);
        this._sprite = RessourcePool_1.RessourcePool.getSprite("flame.png", 1, .8, (0, functions_1.rand)(.01, .1));
        this._sprite.material.color.setHex(0xff0000);
        this._sprite.position.copy(this._pos);
        this._refreshAlpha(visible);
        scene.add(this._sprite);
    }
    get x() {
        return this._pos.x;
    }
    get z() {
        return this._pos.z;
    }
    get id() {
        return this._id;
    }
    _refreshAlpha(visible) {
        this._sprite.material.opacity = visible ? .9 : 0;
    }
    onUpdate(state, spriteEffectsRenderer, ticksCounter, camera, visible) {
        this._pos0.copy(this._pos);
        this._pos.set(state.pos.x, state.pos.y, state.pos.z);
        if (visible) {
            const smokeSprite = RessourcePool_1.RessourcePool.getSprite("smoke2.png", "white");
            const vec = _vec3_0.set(0, .1, 0).add(_vec3_2.set((0, functions_1.rand)(-1, 1), (0, functions_1.rand)(-1, 1), (0, functions_1.rand)(-1, 1)).setLength(.2));
            spriteEffectsRenderer.createSprite({
                pos: this._sprite.position,
                sprite: smokeSprite,
                scaleFunc: function (age) { return (1 - 1 / (age * 1 + 1)) * 1 + .3; },
                alphaFunc: function (age) { return Math.max(0.4 - age * .3, 0); },
                velocity: vec,
                timeToLive: 2,
                moveFunc: function (age) { return (1 - 1 / (age * 1 + 1)) * 2; },
                rotation: Math.random() * Math.PI * 2
            }, ticksCounter);
            const fireSprite = RessourcePool_1.RessourcePool.getSprite("flame.png", this._sprite.material.color);
            const vec2 = _vec3_0.set(0, .1, 0).add(_vec3_2.set((0, functions_1.rand)(-1, 1), (0, functions_1.rand)(-1, 1), (0, functions_1.rand)(-1, 1)).setLength(.1));
            spriteEffectsRenderer.createSprite({
                pos: this._sprite.position,
                sprite: fireSprite,
                scaleFunc: function (age) { return (1 - age / this.timeToLive) * .5; },
                alphaFunc: function (age) { return Math.min(age / this.timeToLive * 3, 1); },
                velocity: vec2,
                timeToLive: (0, functions_1.rand)(.3, .35),
                moveFunc: function (age) { return (1 - 1 / (age * 9 + 1)) * 1; },
                rotation: Math.random() * Math.PI * 2
            }, ticksCounter);
            if (ticksCounter % 2 === 0) {
                const light = RessourcePool_1.RessourcePool.getSprite("light.png", this._sprite.material.color);
                spriteEffectsRenderer.createSprite({
                    pos: (0, functions_1.addScaledVectorTo3d)(_vec3_1.copy(this._sprite.position), camera.position, .3),
                    velocity: _vec3_0.set(0, 0, 0),
                    sprite: light,
                    scaleFunc: function (age) { return ((1 - 1 / (age * 4 + 1)) * 2 + .3) * .61; },
                    alphaFunc: function (age) { return Math.min((1 - age / this.timeToLive) * 1, .9); },
                    timeToLive: (0, functions_1.rand)(.4, .6),
                    moveFunc: function (age) { return (1 - 1 / (age * 1 + 1)) * 1; }
                }, ticksCounter);
            }
        }
        this._refreshAlpha(visible);
        return !this._isDead;
    }
    onHit(scene, spriteEffectsRenderer, tick, visible) {
        this._isDead = true;
        if (visible)
            spriteEffectsRenderer.makeUnitDeathEffect(this._sprite.position, .35, tick, this._sprite.material.color);
        scene.remove(this._sprite);
    }
    onFrame(lerpTime, exactTicks, heightMap) {
        this._sprite.position.lerpVectors(this._pos0, this._pos, lerpTime);
        this._sprite.position.y += (0, functions_2.getGroundHeightAtPosition)(this._sprite.position.x, this._sprite.position.z, heightMap);
        this._sprite.material.rotation = exactTicks * this._rotationSpeed;
        this._sprite.material.color.g = .65 + .2 * Math.sin((exactTicks + this._colorOffset) * .3);
        this._sprite.position.add((0, functions_1.randomPointOnSphere)((0, functions_1.seededRand)(Math.floor(exactTicks / 2.9)) * .05, _vec3_0, Math.floor(exactTicks / 2)));
        this._sprite.scale.setScalar(((0, functions_1.seededRand)(Math.floor(exactTicks / 3)) * .2 + .9) * .4);
    }
}
exports.Projectile = Projectile;
