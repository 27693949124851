"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParticleEffect = void 0;
const three_1 = require("three");
const functions_1 = require("../shared/functions");
const _particleEffects = {};
exports.ParticleEffect = {
    getRandom(id) {
        const textures = _particleEffects[id];
        return textures[Math.floor(Math.random() * textures.length)];
    },
    createImpactEffect(id, count) {
        const w = 128;
        for (let i = 0; i < count; i++) {
            const particles = [];
            const output = [];
            const startAngle = (0, functions_1.rand)(0, Math.PI * 2);
            const circleSpeed = (0, functions_1.rand)(-Math.PI * .5, Math.PI * .5);
            for (let k = 0; k < 5; k++) {
                particles.push({
                    angle: (0, functions_1.rand)(0, Math.PI * 2),
                    speed1: (0, functions_1.rand)(.02, .07),
                    speed2: (0, functions_1.rand)(.02, .07)
                });
            }
            for (let i = 0; i < 10; i++) {
                const canv = document.createElement("canvas");
                const ctx = canv.getContext("2d");
                canv.width = w;
                canv.height = w;
                ctx.lineCap = "round";
                ctx.beginPath();
                const thisStartAngle = startAngle + circleSpeed * i;
                ctx.arc(w * .5, w * .5, w * .05 + w * i * .02, thisStartAngle, thisStartAngle + Math.PI * 2 * .8);
                for (const p of particles) {
                    ctx.moveTo(p.speed1 * i * Math.cos(p.angle) * w + w * .5, p.speed1 * i * Math.sin(p.angle) * w + w * .5);
                    ctx.lineTo(p.speed2 * i * Math.cos(p.angle) * w + w * .5, p.speed2 * i * Math.sin(p.angle) * w + w * .5);
                }
                ctx.strokeStyle = "white";
                ctx.lineWidth = (.12 - i * .012) * w;
                ctx.stroke();
                ctx.strokeStyle = "rgba(255, 222, 222, " + (.3 - i * .03) + ")";
                ctx.lineWidth = (.2 - i * .008) * w;
                ctx.stroke();
                output.push(new three_1.CanvasTexture(canv));
            }
            if (_particleEffects[id] === undefined)
                _particleEffects[id] = [];
            _particleEffects[id].push(output);
        }
    },
    createExplosionEffect(id) {
        const w = 64;
        const steps = 40;
        for (let k = 0; k < 1; k++) {
            const output = [];
            for (let i = 0; i < steps; i++) {
                const perc = i / steps;
                const canv = document.createElement("canvas");
                const ctx = canv.getContext("2d");
                canv.width = w;
                canv.height = w;
                ctx.beginPath();
                ctx.arc(w * .5, w * .5, w * .45, 0, Math.PI * 2);
                ctx.fillStyle = "white";
                ctx.fill();
                for (let j = 0; j < 5; j++) {
                    ctx.strokeStyle = "rgba(255, 255, 255, .2)";
                    ctx.lineWidth = w / 64 * (j * 1.5 + 1);
                    ctx.stroke();
                }
                ctx.globalCompositeOperation = 'destination-out';
                ctx.beginPath();
                ctx.arc(w * .05 + perc * w * .4, w * .5, w * .55 * perc, 0, Math.PI * 2);
                ctx.fill();
                for (let j = 0; j < 5; j++) {
                    ctx.strokeStyle = "rgba(255, 255, 255, .2)";
                    ctx.lineWidth = w / 64 * (j * 1.5 + 1);
                    ctx.stroke();
                }
                output.push(new three_1.CanvasTexture(canv));
            }
            if (_particleEffects[id] === undefined)
                _particleEffects[id] = [];
            _particleEffects[id].push(output);
        }
    },
    createParticcleEffect(config, id, count) {
        var _a;
        for (let i = 0; i < count; i++) {
            const particles = [];
            const output = [];
            for (let k = 0; k < config.countParticles; k++) {
                particles.push({
                    x: 0,
                    y: 0,
                    z: 0,
                    vx: (0, functions_1.rand)(-1, 1) * config.speed,
                    vy: (0, functions_1.rand)(0, 1) * config.speed,
                    vz: (0, functions_1.rand)(-1, 1) * config.speed,
                    size: (0, functions_1.rand)(config.sizeMin, config.sizeMax)
                });
            }
            for (let i = 0; i < config.frames; i++) {
                const canv = document.createElement("canvas");
                const ctx = canv.getContext("2d");
                ctx.imageSmoothingEnabled = false;
                canv.width = config.res;
                canv.height = config.res;
                ctx.fillStyle = config.color;
                for (const p of particles) {
                    p.x += p.vx / config.frames;
                    p.y += p.vy / config.frames;
                    p.z += p.vz / config.frames;
                    const size = Math.max(p.size * (1 - ((_a = config.sizeLoss) !== null && _a !== void 0 ? _a : 0) * i), 0);
                    ctx.fillRect((.5 + p.x - size * .5) * config.res, (.5 - (p.y + p.z * .5) - size * .5) * config.res, size * config.res, size * config.res);
                    /*
                    if(config.lightScale !== undefined)
                    {
                        ctx.drawImage(
                            img.something,
                            (.5 + p.x - size * .5 * config.lightScale) * config.res,
                            (.5 - (p.y + p.z * .5) - size * .5 * config.lightScale) * config.res,
                            size * config.lightScale * config.res,
                            size * config.lightScale * config.res
                        );
                    }
                    */
                }
                output.push(new three_1.CanvasTexture(canv));
            }
            if (_particleEffects[id] === undefined)
                _particleEffects[id] = [];
            _particleEffects[id].push(output);
        }
    }
};
