"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModelLoader = void 0;
const OBJLoader_1 = require("three/examples/jsm/loaders/OBJLoader");
const FBXLoader_1 = require("three/examples/jsm/loaders/FBXLoader");
const GLTFLoader_js_1 = require("three/examples/jsm/loaders/GLTFLoader.js");
const three_1 = require("three");
const _objLoader = new OBJLoader_1.OBJLoader();
const _fbxLoader = new FBXLoader_1.FBXLoader();
const _gltfLoader = new GLTFLoader_js_1.GLTFLoader();
const loadModelAsync = (path, then) => __awaiter(void 0, void 0, void 0, function* () {
    const fileType = path.split('.').pop();
    if (fileType === "fbx") {
        const model = yield _fbxLoader.loadAsync(path);
        then(model);
        return;
    }
    if (fileType === "obj") {
        const model = yield _objLoader.loadAsync(path);
        then(model);
        return;
    }
    if (fileType === "gltf" || fileType === "glb") {
        const model = yield _gltfLoader.loadAsync(path);
        then(model.scene);
        return;
    }
});
const _models = {};
exports.ModelLoader = {
    getModelByPath(path) {
        return _models[path];
    },
    getRootModelByPath(path) {
        let model = _models[path];
        while (model.children.length > 0)
            model = model.children[0];
        return model;
    },
    getModelCopyByPath(path, copyTexture) {
        const model = exports.ModelLoader.getModelByPath(path);
        return new three_1.Mesh(model.geometry, copyTexture ? model.material.clone() : model.material);
        // return new Mesh(model.geometry, model.material);
    },
    getRootModelCopyByPath(path, copyTexture) {
        const model = exports.ModelLoader.getRootModelByPath(path);
        return new three_1.Mesh(model.geometry, copyTexture ? model.material.clone() : model.material);
        // return new Mesh(model.geometry, model.material);
    },
    loadModelAsync(path, texture) {
        return __awaiter(this, void 0, void 0, function* () {
            yield loadModelAsync(path, (model) => {
                if (texture !== undefined)
                    model.children.forEach(child => child.material = new three_1.MeshToonMaterial({ map: texture, transparent: true, opacity: .5 }));
                model.traverse((child) => {
                    if (child.isMesh) {
                        const mat = child.material;
                        // mat.color.setHex(0xffffff);
                        // mat.metalness = 0;
                        // mat.roughness = 1;
                        // mat.shininess = 0;
                        child.castShadow = true;
                        child.receiveShadow = true;
                    }
                });
                _models[path] = model;
            });
        });
    },
    loadModelsAsync(path, ...models) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(models.map(model => exports.ModelLoader.loadModelAsync(path + model)));
        });
    }
};
