"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroundOrderRenderer = void 0;
const data_1 = require("../shared/data");
const functions_1 = require("./functions");
class GroundOrderRenderer {
    constructor() {
        this._groundOrders = [];
    }
    createGroundOrder(ticksCounter, pos) {
        this._groundOrders.push({
            tickOfCreation: ticksCounter,
            x: pos.x,
            y: pos.y
        });
    }
    onFrame(ticksCounter, ctx) {
        for (let i = 0; i < this._groundOrders.length; i++) {
            const g = this._groundOrders[i];
            const age = ticksCounter - g.tickOfCreation;
            if (age > 20) {
                this._groundOrders.splice(i, 1);
                i--;
                continue;
            }
            const scale = age * .22;
            const x = g.x * data_1.Global.visionFieldSize;
            const y = g.y * data_1.Global.visionFieldSize;
            (0, functions_1.drawCircle)(ctx, x, y, data_1.Global.visionFieldSize * scale * .5, "rgba(255, 255, 255, " + (Math.max(0, 1.7 - scale * 1.5) * 1.5) + ")", undefined, data_1.Global.visionFieldSize * .1);
            (0, functions_1.drawCircle)(ctx, x, y, Math.max(.12 - scale * .033, 0) * data_1.Global.visionFieldSize * .66, "rgba(255, 255, 255, " + Math.max(0, 1.5 - scale * 1.5) * .5 + ")", undefined, data_1.Global.visionFieldSize * .1);
            /*
            g.circle1.scale.setScalar(scale * .75);
            g.material1.opacity = Math.max(0, 1.7 - scale * 1.5);
            g.circle1.position.y = g.pos.y + .04 + ((age * .1) % .12) * .5;
    
            g.circle2.scale.setScalar(.12 - scale * .033);
            g.material2.opacity = Math.max(0, 1.5 - scale * 1.5) * .35;
            g.circle2.position.y = g.circle1.position.y + .01;
            */
        }
    }
}
exports.GroundOrderRenderer = GroundOrderRenderer;
