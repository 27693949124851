"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.items = void 0;
const data_1 = require("./data");
exports.items = [
    {
        nr: 1,
        id: "potion",
        name: "Potion",
        description: "Heals your sheep",
        icon: "potion.png",
        charges: 1,
        heals: 100,
        cooldownAtLvl: (lvl) => 20,
        costAtLvl: (lvl) => 100
    },
    {
        nr: 2,
        id: "scanner",
        name: "Scanner",
        description: "Reveals locations of enemy sheep for a short duration",
        icon: "scanner.png",
        cooldownAtLvl: (lvl) => Math.round(data_1.Global.ticksPerSec * 90 * Math.pow(.8, lvl - 1)),
        durationAtLvl: (lvl) => (5 + (lvl - 1) * 1) * data_1.Global.ticksPerSec,
        scans: true,
        costAtLvl: (lvl) => Math.round(-100 + Math.pow(1.25, lvl - 1) * 200)
    },
    {
        nr: 3,
        id: "sprint",
        name: "Sprint",
        description: "Increases your movement speed for a short duration",
        icon: "sprint.png",
        cooldownAtLvl: (lvl) => Math.round(data_1.Global.ticksPerSec * 120 * Math.pow(.8, lvl - 1)),
        costAtLvl: (lvl) => Math.round(-100 + Math.pow(1.25, lvl - 1) * 200),
        speedMultiplierAtLvl: (lvl) => 2 + (lvl - 1) * .1,
        durationAtLvl: (lvl) => (2 + (lvl - 1) * .33333) * data_1.Global.ticksPerSec,
    },
    {
        nr: 4,
        id: "blink",
        name: "Teleport",
        description: "Teleports your sheep over a short distance",
        icon: "blink.png",
        cooldownAtLvl: (lvl) => Math.round(data_1.Global.ticksPerSec * 120 * Math.pow(.8, lvl - 1)),
        tpDistanceAtLvl: (lvl) => 4 + (lvl - 1) * .33333,
        costAtLvl: (lvl) => Math.round(-100 + Math.pow(1.25, lvl - 1) * 200),
        targetIsPoint: true
    }
];
