"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeRandomHeightMap = exports.makeRandomMap = void 0;
const data_1 = require("./data");
const makeRandomMap = (x, y) => {
    const tiles = [];
    /*
    for(let i = 0; i < 100; i++)
    {
        tiles.push({
            x: Math.floor(Math.random() * x),
            z: Math.floor(Math.random() * y),
            type: ["tree1", "tree2", "tree3"][Math.floor(Math.random() * 3)],
            rot: {
                x: Math.random() * Math.PI * 2,
                y: Math.random() * Math.PI * 2,
                z: Math.random() * Math.PI * 2
            }
        });
    }
    */
    return {
        groundTexture: "grass4.jpg",
        flowers: ["flower.png", "flowerYellow", "flowerBlue", "flowerWhite"],
        heightMap: (0, exports.makeRandomHeightMap)(x + data_1.Global.mapSideDeadZone * 2 + 1, y + data_1.Global.mapTopDeadZone + data_1.Global.mapBottomDeadZone + 1),
        tiles,
        x,
        y,
        collision: []
    };
};
exports.makeRandomMap = makeRandomMap;
const makeRandomHeightMap = (sizeX, sizeY) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    const arr = new Array(sizeX);
    for (let x = 0; x < sizeX; x++)
        arr[x] = new Array(sizeY);
    for (let x = 0; x < sizeX; x++) {
        for (let y = 0; y < sizeY; y++)
            arr[x][y] = Math.random();
    }
    for (let i = 0; i < 3; i++) {
        for (let x = 0; x < sizeX; x++) {
            for (let y = 0; y < sizeY; y++) {
                const neighbors = [
                    (_b = (_a = arr[x - 1]) === null || _a === void 0 ? void 0 : _a[y - 1]) !== null && _b !== void 0 ? _b : .5,
                    (_d = (_c = arr[x]) === null || _c === void 0 ? void 0 : _c[y - 1]) !== null && _d !== void 0 ? _d : .5,
                    (_f = (_e = arr[x + 1]) === null || _e === void 0 ? void 0 : _e[y - 1]) !== null && _f !== void 0 ? _f : .5,
                    (_h = (_g = arr[x - 1]) === null || _g === void 0 ? void 0 : _g[y]) !== null && _h !== void 0 ? _h : .5,
                    (_k = (_j = arr[x + 1]) === null || _j === void 0 ? void 0 : _j[y]) !== null && _k !== void 0 ? _k : .5,
                    (_m = (_l = arr[x - 1]) === null || _l === void 0 ? void 0 : _l[y + 1]) !== null && _m !== void 0 ? _m : .5,
                    (_p = (_o = arr[x]) === null || _o === void 0 ? void 0 : _o[y + 1]) !== null && _p !== void 0 ? _p : .5,
                    (_r = (_q = arr[x + 1]) === null || _q === void 0 ? void 0 : _q[y + 1]) !== null && _r !== void 0 ? _r : .5
                ];
                arr[x][y] = neighbors.reduce((a, b) => a + b, 0) / neighbors.length;
            }
        }
    }
    for (let x = 0; x < sizeX; x++) {
        for (let y = 0; y < sizeY; y++)
            arr[x][y] = Math.round(arr[x][y] * data_1.Global.heightmapPrecision);
    }
    return arr;
};
exports.makeRandomHeightMap = makeRandomHeightMap;
