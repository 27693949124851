"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KEY = void 0;
var KEY;
(function (KEY) {
    KEY[KEY["UP"] = 38] = "UP";
    KEY[KEY["DOWN"] = 40] = "DOWN";
    KEY[KEY["LEFT"] = 37] = "LEFT";
    KEY[KEY["RIGHT"] = 39] = "RIGHT";
    KEY[KEY["A"] = 65] = "A";
    KEY[KEY["B"] = 66] = "B";
    KEY[KEY["C"] = 67] = "C";
    KEY[KEY["D"] = 68] = "D";
    KEY[KEY["E"] = 69] = "E";
    KEY[KEY["F"] = 70] = "F";
    KEY[KEY["G"] = 71] = "G";
    KEY[KEY["H"] = 72] = "H";
    KEY[KEY["I"] = 73] = "I";
    KEY[KEY["J"] = 74] = "J";
    KEY[KEY["K"] = 75] = "K";
    KEY[KEY["L"] = 76] = "L";
    KEY[KEY["M"] = 77] = "M";
    KEY[KEY["N"] = 78] = "N";
    KEY[KEY["O"] = 79] = "O";
    KEY[KEY["P"] = 80] = "P";
    KEY[KEY["Q"] = 81] = "Q";
    KEY[KEY["R"] = 82] = "R";
    KEY[KEY["S"] = 83] = "S";
    KEY[KEY["T"] = 84] = "T";
    KEY[KEY["U"] = 85] = "U";
    KEY[KEY["V"] = 86] = "V";
    KEY[KEY["W"] = 87] = "W";
    KEY[KEY["X"] = 88] = "X";
    KEY[KEY["Y"] = 89] = "Y";
    KEY[KEY["Z"] = 90] = "Z";
    KEY[KEY["SHIFT"] = 16] = "SHIFT";
    KEY[KEY["STRG"] = 17] = "STRG";
    KEY[KEY["ALT"] = 18] = "ALT";
    KEY[KEY["NUM1"] = 49] = "NUM1";
    KEY[KEY["NUM2"] = 50] = "NUM2";
    KEY[KEY["NUM3"] = 51] = "NUM3";
    KEY[KEY["NUM4"] = 52] = "NUM4";
    KEY[KEY["NUM5"] = 53] = "NUM5";
    KEY[KEY["NUM6"] = 54] = "NUM6";
    KEY[KEY["NUM7"] = 55] = "NUM7";
    KEY[KEY["NUM8"] = 56] = "NUM8";
    KEY[KEY["NUM9"] = 57] = "NUM9";
    KEY[KEY["NUM0"] = 48] = "NUM0";
    KEY[KEY["CIRCUMFLEX"] = window.chrome ? 220 : 160] = "CIRCUMFLEX";
    KEY[KEY["ENTER"] = 13] = "ENTER";
    KEY[KEY["BACKSPACE"] = 8] = "BACKSPACE";
    KEY[KEY["DELETE"] = 46] = "DELETE";
    KEY[KEY["PAUSE"] = 19] = "PAUSE";
    KEY[KEY["F1"] = 112] = "F1";
    KEY[KEY["F2"] = 113] = "F2";
    KEY[KEY["F3"] = 114] = "F3";
    KEY[KEY["F4"] = 115] = "F4";
    KEY[KEY["F5"] = 116] = "F5";
    KEY[KEY["F6"] = 117] = "F6";
    KEY[KEY["F7"] = 118] = "F7";
    KEY[KEY["F8"] = 119] = "F8";
    KEY[KEY["F9"] = 120] = "F9";
    KEY[KEY["F10"] = 121] = "F10";
    KEY[KEY["ESC"] = 27] = "ESC";
    KEY[KEY["TAB"] = 9] = "TAB";
    KEY[KEY["PLUS"] = 107] = "PLUS";
    KEY[KEY["MINUS"] = 109] = "MINUS";
    KEY[KEY["SPACE"] = 32] = "SPACE";
    KEY[KEY["CAPSLOCK"] = 20] = "CAPSLOCK";
    KEY[KEY["AE"] = 192] = "AE";
})(KEY || (exports.KEY = KEY = {}));
;
